import React, { useEffect } from 'react'
import image from "../../images/footer-bg-2.jpg";
import { Link } from 'react-router-dom';

function _100_Up_Ccoming(props) {
    useEffect(() => {
        props.navbarStyle(true);
    }, [])
    return (
        <>
            <div className='breadcrumbs_bg h-[11rem] 2xl:h-[20rem] flex justify-center items-center' style={{ backgroundImage: `url(${image})` }}>

                <nav class="breadcrumbs" style={{ opacity: "1" }}>
                    <Link to={'/'} class="breadcrumbs__item"><i class="text-black fa-solid fa-house"></i> Home</Link>
                    {/* <Link to={'/'} class="breadcrumbs__item">Shop</Link> */}
                    {/* <Link to={'/'} class="breadcrumbs__item">Cart</Link> */}
                    <Link to={'/'} class="breadcrumbs__item is-active">Coming Soon</Link>
                </nav>

            </div>
            <div className='h-[100vh] flex justify-center items-center'>
                <div class=" ">
                    <div class="min-h-screen flex items-center justify-center">
                        <div class="max-w-2xl w-full px-4">
                            <h1 class="text-4xl font-bold text-center mb-8 text-gray-900 dark:text-white">Coming Soon!</h1>
                            <p class="text-lg text-gray-600 dark:text-gray-300 text-center mb-12">Our website is under construction. We'll be back soon!
                            </p>
                            {/* <form class="flex flex-col md:flex-row justify-center items-center gap-4">
                                <input class="w-full md:w-80  py-2 px-4 border text-gray-800 dark:text-white border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700" type="email" placeholder="Enter your email address" />
                                <button class="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 border dark:bg-blue-800">Notify Me</button>
                            </form> */}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default _100_Up_Ccoming