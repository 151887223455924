import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import About_us_1 from "../../images/newabout-3.png";
import About_us_2 from "../../images/AboutUs_2.png";
import About_us_11 from "../../images/AboutUs_11.svg"
import About_us_12 from "../../images/AboutUs_12.jpg"
import About_us_13 from "../../images/AboutUs_13.gif"
import About_us_14 from "../../images/about-us.png"
import About_us_15 from "../../images/5155707_2717001.png"
import IspDigitals_4 from ".././../images/ispDigital_4.svg";
import footer_log from "../../images/footer-bg-2.jpg"
export default function _02_New_AboutUs(props) {
    useEffect(() => {
        props.navbarStyle(true);
        
        return () => {
            
        }
    }, [])



    // freequiently ask question section starts
    const hiddenClicker = (element) => {
        let parent = element.target.closest('.Frequently_ask_box_main')
        if (parent !== null) {
            let icon = parent.getElementsByClassName('fa-solid')[0]
            let boxs = parent.getElementsByClassName('Frequently_ask_box')[0]
            let main_box = document.getElementsByClassName('Frequently_ask_box')
            if (!boxs.classList.contains('Frequently_ask_box_vissible')) {
                for (let index = 0; index < main_box.length; index++) {
                    const element = main_box[index];
                    if (element.classList.contains('Frequently_ask_box_vissible')) {
                        element.classList.remove('Frequently_ask_box_vissible')
                        let sub_parent = element.closest('.Frequently_ask_box_main')
                        let icon2 = sub_parent.getElementsByClassName('fa-solid')[0]
                        icon2.classList.remove('fa-circle-xmark')
                        icon2.classList.add('fa-chevron-down')
                    }
                }
                boxs.classList.add("Frequently_ask_box_vissible")
                icon.classList.remove('fa-chevron-down')
                icon.classList.add('fa-circle-xmark')
            }
            else {
                for (let index = 0; index < main_box.length; index++) {
                    const element = main_box[index];
                    if (element.classList.contains('Frequently_ask_box_vissible')) {
                        element.classList.remove('Frequently_ask_box_vissible')
                        let sub_parent = element.closest('.Frequently_ask_box_main')
                        let icon2 = sub_parent.getElementsByClassName('fa-solid')[0]
                        icon2.classList.remove('fa-circle-xmark')
                        icon2.classList.add('fa-chevron-down')
                    }
                }
                boxs.classList.remove("Frequently_ask_box_vissible")
                icon.classList.add('fa-chevron-down')
                icon.classList.remove('fa-circle-xmark')
            }
        }
    }
    // freequiently ask question section ends

    return (
        <>
            <div className='breadcrumbs_bg h-[11rem] 2xl:h-[20rem] flex justify-center items-center'style={{backgroundImage:`url(${footer_log}) `}}>

                <nav class="breadcrumbs" style={{ opacity: "1" }}>
                    <Link to={'/'} class="breadcrumbs__item"><i class="text-black fa-solid fa-house"></i> Home</Link>
                    {/* <Link to={'/'} class="breadcrumbs__item">Shop</Link> */}
                    {/* <Link to={'/'} class="breadcrumbs__item">Cart</Link> */}
                    <Link to={'/aboutUs'} class="breadcrumbs__item is-active">About Us</Link>
                </nav>

            </div>
            <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                <h2 className='text-web-color text-center mx-auto text-xl md:text-3xl lg:text-4xl font-bold'>About Us </h2>
            </div>

            <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                    <div className='m-auto lg:mx-0 '>
                        <img  src={About_us_12} alt="" className='rounded-lg w-[34rem]' />
                    </div>
                    <div className='mt-10 lg:ms-6' >
                        <h3 className='text-web-color text-xl md:text-3xl lg:text-4xl font-bold'>Welcome to WebShinez,</h3>
                        <p className='mt-5 text-base'> Your trusted partner in enterprise resource planning (ERP) solutions for over a decade in Bangladesh. At WebShinez, we believe in harnessing the power of technology to transform businesses, driving efficiency, and fostering growth</p>
                        {/* <button className='mt-5 rounded-sm px-6 py-2  text-base vanila_btn_hover_1'>Know More</button> */}
                    </div>

                </div>
            </div>

            <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%] ' style={{ backgroundColor: "#EDF0F8" }}>
                <div className='grid grid-cols-1 lg:grid-cols-2 '>

                    <div className='mt-10 lg:me-8' >
                        <h3 className='text-web-color text-xl md:text-3xl lg:text-4xl font-bold'>Our Journey
                        </h3>
                        <p className='mt-5 text-base'>Established in 2015 Webshinez embarked on a mission to revolutionize the way businesses operate. Over the past ten years, we've remained committed to delivering cutting-edge ERP solutions that empower organizations across various industries to thrive in an ever-evolving digital landscape.
                        </p>
                        {/* <button className='mt-5 rounded-sm px-6 py-2  text-base vanila_btn_hover_1'>Know More</button> */}
                    </div>
                    <div className="mx-auto lg:mx-0 ">
                        <img width="80%" className='ms-auto' src={About_us_2} alt="" />
                    </div>

                </div>
            </div>
            <div id='knowMOre_about_ispDigital' className='px-4 md:px-16 py-20 lg:px-32 lg:py-20 2xl:px-[27%]' style={{ backgroundColor: "#EDF0F8" }}>
                <h1 className='text-center text-web-color font-semibold text-xl md:text-3xl lg:text-4xl'>Know More About Web Shinez Technologies</h1>
                <div className='mt-10 grid grid-cols-1 lg:grid-cols-2 gap-10'>
                    <div className='mx-auto'>
                        <img className=' rounded-lg w-[78rem] mt-10' src={About_us_15} alt="" />
                    </div>
                    <div className='mt-6 lg:ms-6 '>
                        <div className=' Frequently_ask_box_main box-border rounded-md drop-shadow-md w-full  bg-white px-4 py-2 my-5'  >
                            <div className='flex justify-between' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Our Comprehensive Software Solutions.
</h5>
                                <span className=' text-web-color font-bold text-2xl'><i className="fa-solid fa-chevron-down"></i></span>
                            </div>
                            <div className=' Frequently_ask_box Frequently_ask_box_vissible'>
                                <hr />
                                <p className='ps-1 pt-6 pb-4'>Trust in our expertise to revolutionize your business landscape, unlocking its full potential and propelling it towards sustainable prosperity. Experience the power of synergy with our comprehensive Software solutions</p>
                            </div>
                        </div>
                        <div className=' Frequently_ask_box_main box-border rounded-md drop-shadow-md w-full  bg-white px-4 py-2 my-5'  >
                            <div className='flex justify-between' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Our School Solution</h5>
                                <span className=' text-web-color font-bold text-2xl'><i className="fa-solid fa-chevron-down"></i></span>
                            </div>
                            <div className=' Frequently_ask_box'>
                                <hr />
                                <p className='ps-1 pt-6 pb-4'>Empowering Education, Simplifying Administration: Your School's Success Starts Here. With our intuitive management software, streamline operations, enhance communication, and elevate learning experiences. From attendance tracking to Result management, we provide comprehensive solutions tailored to your institution's needs.</p>
                            </div>
                        </div>
                        <div className=' Frequently_ask_box_main box-border rounded-md drop-shadow-md w-full  bg-white px-4 py-2 my-5'  >
                            <div className='flex justify-between' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Our Madrasha Solution</h5>
                                <span className=' text-web-color font-bold text-2xl'><i className="fa-solid fa-chevron-down"></i></span>
                            </div>
                            <div className=' Frequently_ask_box'>
                                <hr />
                                <p className='ps-1 pt-6 pb-4'>Embrace efficiency, embrace innovation, and watch your Madrasha thrive with our cutting-edge technology. Join the countless educators, administrators, and parents who trust our platform to optimize every aspect of the educational journey. Experience the ease of managing your Madrasha with precision and ease. Together, let's shape a brighter future for education, one seamless solution at a time.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%] ' >
                <div className='grid grid-cols-1 lg:grid-cols-2 '>
                    <div className="mx-auto lg:mx-0">
                        <img width="80%" className='lg:me-auto lg:ms-0 ' src={About_us_14} alt="" />
                    </div>
                    <div className='mt-10 lg:ms-6' >
                        <h3 className='text-web-color text-xl md:text-3xl lg:text-4xl font-bold'>Our Vision
                        </h3>
                        <p className='mt-5 text-base'>
                            Our vision is to be the premier provider of ERP solutions in Bangladesh, known for our innovation, reliability, and customer-centric approach. We aim to continue pioneering advancements in ERP technology, empowering businesses of all sizes to achieve their full potential.

                        </p>
                        {/* <button className='mt-5 rounded-sm px-6 py-2  text-base vanila_btn_hover_1'>Know More</button> */}
                    </div>


                </div>
            </div>
           
        </>
    )
}
