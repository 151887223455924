import React, { useEffect, useState, useRef } from 'react'

import { Link, useParams } from "react-router-dom";
export default function Services(props) {
    const params = useParams()
    useEffect(() => {
        props.navbarStyle(true)
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        document.getElementsByClassName('services_title_box')[params.id - 1] && document.getElementsByClassName('services_title_box')[params.id - 1]
            .click();
    })

    console.log(params.id)

    const serviceClick = (e) => {
        let clicked_services_title_box = e.target.closest(".services_title_box");
        let content_index = clicked_services_title_box.getAttribute("data-content-index");
        let content_elements = document.getElementsByClassName("services_content_box");
        let all_services_title_box = document.getElementsByClassName('services_title_box');
        Array.from(all_services_title_box).forEach(ele => {
            if (ele.classList.contains("border-[1px]", "text-[--Primary-color]", "border-[--Primary-color]")) {
                ele.style.removeProperty("transition")
                ele.classList.remove("border-[1px]", "text-[--Primary-color]", "border-[--Primary-color]")
                // all_services_title_box[ele.getAttribute("data-content-index")].classList.remove("opacity-100" ,"vissible")
                content_elements[ele.getAttribute("data-content-index")].classList.add("services_content_hidden")
            }
        })
        if (!clicked_services_title_box.classList.contains("border-[1px]", "text-[--Primary-color]", "border-[--Primary-color]")) {
            clicked_services_title_box.classList.add("border-[1px]", "text-[--Primary-color]", "border-[--Primary-color]");
            // content_elements[content_index].classList.add("opacity-100" ,"vissible")
            content_elements[content_index].classList.remove("services_content_hidden")
        }
    }
    return (
        <>
            <div>
                <div className='breadcrumbs_bg h-[11rem] 2xl:h-[20rem] flex justify-center items-center' style={{ background: "url(https://source.unsplash.com/random?erp) " }}>

                    <nav class="breadcrumbs" style={{ opacity: "1" }}>
                        <Link to={'/'} class="breadcrumbs__item"><i class="text-black fa-solid fa-house"></i> Home</Link>

                        <Link to={'/services/' + params.id} class="breadcrumbs__item is-active">Services</Link>
                    </nav>

                </div>
                <div className="px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]">
                    <div className="flex flex-col md:flex-row justify-between">
                        <div className="md:w-[35%]">

                            <div onClick={(e) => { serviceClick(e) }} data-content-index="0" className="services_title_box  bg-[#E7F4FF] w-full mb-4 py-3 px-4 rounded-md border-[1px] text-[--Primary-color] border-[--Primary-color]">
                                <div className="text-xl flex justify-between">
                                    <span>
                                        Website Development
                                    </span>
                                    <span><i class="fa-solid fa-globe"></i></span>
                                </div>
                            </div>
                            <div onClick={(e) => { serviceClick(e) }} data-content-index="1" className="services_title_box  bg-[#E7F4FF] w-full mb-4 py-3 px-4 rounded-md ">
                                <div className="text-xl flex justify-between">
                                    <span>
                                        Digital Marketing
                                    </span>
                                    <span><i class="fa-solid fa-bag-shopping"></i></span>
                                </div>
                            </div>
                            <div onClick={(e) => { serviceClick(e) }} data-content-index="2" className="services_title_box  bg-[#E7F4FF] w-full mb-4 py-3 px-4 rounded-md ">
                                <div className="text-xl flex justify-between">
                                    <span>
                                        Domain Registration & Hosting
                                    </span>
                                    <span><i class="fa-solid fa-registered"></i></span>
                                </div>
                            </div>
                            <div onClick={(e) => { serviceClick(e) }} data-content-index="3" className="services_title_box  bg-[#E7F4FF] w-full mb-4 py-3 px-4 rounded-md ">
                                <div className="text-xl flex justify-between">
                                    <span>
                                        Software Development
                                    </span>
                                    <span><i class="fa-brands fa-squarespace"></i></span>
                                </div>
                            </div>
                            <div onClick={(e) => { serviceClick(e) }} data-content-index="4" className="services_title_box  bg-[#E7F4FF] w-full mb-4 py-3 px-4 rounded-md ">
                                <div className="text-xl flex justify-between">
                                    <span>
                                        Mobile App Development
                                    </span>
                                    <span><i class="fa-brands fa-adn"></i></span>
                                </div>
                            </div>
                            <div onClick={(e) => { serviceClick(e) }} data-content-index="5" className="services_title_box  bg-[#E7F4FF] w-full mb-4 py-3 px-4 rounded-md ">
                                <div className="text-xl flex justify-between">
                                    <span>
                                        Software Development
                                    </span>
                                    <span><i class="fa-solid fa-briefcase"></i></span>
                                </div>
                            </div>

                        </div>
                        <div className="mt-10 md:mt-0 md:w-[60%] h-[60rem] md:h-[60rem] lg:h-[40rem] relative ">
                            <div className="services_content_box text-center md:text-left  absolute top-0 services_content_hidden">
                                <div>
                                    {/* <span className="text-6xl "><i class="fa-solid fa-globe"></i></span> */}
                                    <p className=" text-base text-left"> We develop dynamic responsive website, e-commerce stores, different kinds of web portal and other web application with easy to use admin panel to edit images and other contents. Our scripts are SEO friendly which helps your website to rank higher. When it comes to creating robust, dynamic, and user-friendly websites, there's a myriad of elements that contribute to the success and efficiency of online platforms. From developing responsive websites and e-commerce stores to constructing various web portals and applications, the focus remains on enhancing user experience, improving manageability, and optimizing for search engines. Here’s a deeper dive into the essentials of such web development services and their strategic importance.
                                        In the current digital landscape, a website must perform flawlessly across all devices—be it a desktop, tablet, or smartphone. Responsive web design ensures that a site’s layout adjusts according to the screen size and resolution. This adaptability improves user experience and accessibility, which are key factors in retaining visitors and converting leads. By implementing flexible images, fluid grids, and CSS styling, developers can create a seamless experience for all users.</p>
                                </div>
                            </div>
                            <div className="services_content_box text-center md:text-left absolute top-0 services_content_hidden">
                                <div>
                                    {/* <span className="text-6xl  "><i class="fa-solid fa-bag-shopping"></i></span> */}
                                    <p className=" text-base text-left">WebShinez Technologies offers comprehensive online marketing solutions focused on enhancing your digital presence, increasing conversions, and nurturing long-term engagement for your business. Our services include SMS marketing, email marketing, SEO, and a wide range of PPC strategies tailored to your needs. At WebShinez Technologies, we understand that the digital landscape is constantly evolving, and so are the ways in which businesses need to engage with their customers. Our comprehensive online marketing solutions are designed to boost your digital presence, enhance user engagement, and ultimately drive conversions. By combining cutting-edge technology with proven strategies, we ensure that your business stands out in a crowded market.

                                        Our SMS marketing services provide a direct and personalized way to reach customers, offering timely updates, promotions, and important notifications right to their mobile devices. This method is highly effective due to its immediate and actionable nature, ensuring high open and read rates compared to other marketing channels.

                                        Email marketing remains a cornerstone of digital strategy, known for its excellent return on investment. We craft compelling email campaigns that are not only visually appealing but also optimized for engagement and conversion. These campaigns are tailored to resonate with your audience, reinforcing brand loyalty and encouraging repeat business.</p>
                                </div>
                            </div>
                            <div className="services_content_box text-center md:text-left absolute top-0 services_content_hidden">
                                <div>
                                    {/* <span className="text-6xl "><i class="fa-solid fa-registered"></i></span> */}
                                    <p className=" text-base text-left">We provide top-tier Domain Registration & Hosting services with uncompromising security. Our prices are highly competitive, catering to both corporate and non-corporate needs with affordable packages. At our core, we understand the foundational role that domain registration and hosting play in the digital success of any business. That’s why we focus on offering top-tier services that not only meet but exceed the essential needs of online stability, performance, and security. Our offerings are designed to cater to a wide range of requirements, ensuring that whether you are a startup, a growing business, or an established corporation, you'll find solutions that are tailored to your needs.

                                        Security is non-negotiable in our domain registration and hosting services. We employ advanced security measures to protect your online assets from both common threats and sophisticated cyber attacks. Our robust security protocols ensure that your data is encrypted, secure, and backed up regularly, providing peace of mind and safeguarding your business’s online presence.

                                        Price is also a key consideration in our service design. We strive to keep our prices highly competitive without compromising on quality or security. This approach makes our services accessible to both corporate clients and smaller, non-corporate entities. Our range of affordable packages means that businesses of any size can find a hosting plan that fits their budget while still enjoying reliable and secure hosting solutions.</p>
                                </div>
                            </div>
                            <div className="services_content_box text-center md:text-left absolute top-0 services_content_hidden">
                                <div>
                                    {/* <span className="text-6xl "><i class="fa-brands fa-squarespace"></i></span> */}
                                    <p className=" text-base text-left">Welcome to Webshinez Technologies, a leading software development company based in Bangladesh. Specializing in crafting exceptional solutions for mobile, web, and Windows platforms, we excel in developing a variety of software applications. In the rapidly evolving field of software development, a systematic and structured approach is crucial for the successful creation, design, deployment, and maintenance of software. The process begins with thorough planning and analysis, where developers gather insights into the client’s needs and expectations. This step ensures that all functionalities required by the end-users are clearly understood and documented.

                                        Following the planning stage, the design phase outlines the software architecture. This involves selecting the right technology stack and creating a blueprint for the development process, focusing on user interface, user experience, and system design. The goal is to craft a framework that not only supports the software's requirements but also ensures scalability and robustness.</p>
                                </div>
                            </div>
                            <div className="services_content_box text-center md:text-left absolute top-0 services_content_hidden">
                                <div>
                                    {/* <span className="text-6xl "><i class="fa-brands fa-adn"></i></span> */}
                                    <p className=" text-base text-left">As a trusted Mobile App Development Company, we specialize in delivering professional Android app development services. Our seasoned team conducts thorough research and analysis to precisely meet your needs and surpass your expectations. In the rapidly evolving mobile marketplace, having a bespoke Android app can set a business apart. As a trusted mobile app development company, we understand the importance of creating apps that are not only functional but also innovative and user-centric. Our commitment to professional Android app development is demonstrated through our meticulous approach to research and analysis, ensuring that every app we develop is tailored to meet the unique requirements and goals of our clients.

                                        Our seasoned team of developers, UX/UI designers, and project managers work collaboratively to transform your ideas into reality. We start by conducting an in-depth analysis of your business objectives, target audience, and competition. This strategic approach helps us to understand the nuances of your market and identify key features that will make your app stand out. By focusing on what truly matters to your users, we create apps that are engaging, intuitive, and deliver a seamless user experience.</p>
                                </div>
                            </div>
                            <div className="services_content_box text-center md:text-left absolute top-0 services_content_hidden">
                                <div>
                                    {/* <span className="text-6xl "><i class="fa-solid fa-briefcase"></i></span> */}
                                    <p className=" text-base text-left">The surge in eCommerce Websites' popularity in Bangladesh is noticeable day by day. Transitioning your local shop or business into an online platform is made effortless with an eCommerce Website, leading to rapid boosts in sales and revenue. For local businesses, an eCommerce website acts as a 24/7 sales channel, accessible from anywhere. This availability is crucial in today's fast-paced world, where customers expect to shop at their convenience, without being restricted by store hours or geographic limitations. Online platforms also enable businesses to showcase a wider range of products than might be feasible in a physical store, thus attracting a diverse customer demographic looking for varied and specific products.

                                        Moreover, eCommerce websites allow businesses to utilize advanced analytics tools to track consumer behavior, preferences, and buying patterns. These insights are invaluable as they help businesses tailor their offerings, improve customer engagement, and make informed decisions about stock and marketing strategies. Leveraging data effectively can lead to more personalized shopping experiences, increasing customer satisfaction and loyalty.

                                        The process of setting up an eCommerce website has become increasingly streamlined, thanks to user-friendly platforms that offer integrated solutions, including inventory management, secure payment gateways, and customer support systems. These platforms are designed to be manageable even by those with minimal technical expertise, making the digital transition smoother for traditional business owners.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >

            </div >
        </>
    )
}