import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Slider_Circle from "../images/home-slider-animation.png";
import Slider_Shape from "../images/home-slider-animation-shape.png";
import Slide_Anime_Img_1 from "../images/home-slider-animation_children_1 (1).png";
import Slide_Anime_Img_2 from "../images/home-slider-animation_children_2.png";
// import Slide_Anime_Img_1 from "../images/home-slider-animation_children_3 (1).png";
import Platform_1 from "../images/platform-1.png";
import Slide_Anime_Img_3 from "../images/home-slider-animation_children_3.png";
import Slide_Anime_Img_4 from "../images/home-slider-animation_children_4.png";
import Slide_Anime_Img_5 from "../images/home-slider-animation_children_5.png";
import Slide_Anime_Img_1_to_5 from "../images/home-slider-animation_children_1_to_5.png";
import Front_Image from "../images/front_slider.jpg";
import Question_mark from "../images/Question_mark.png";
import Crousel_Main_1 from "../images/crousel_main.jpg";
import Crousel_Main_2 from "../images/crousel_main_1.jpg";
import Crousel_Main_3 from "../images/crousel_main_2.png";
import Crousel_Main_4 from "../images/crousel_main_3.jpg";
import Image1 from "../images/card (1).svg";
import Image2 from "../images/card (2).svg";
import Image3 from "../images/card (3).svg";
import Image4 from "../images/card (4).svg";
import Product_1 from "../images/product_1.png"
import Product_2 from "../images/product_2.png"
import Product_3 from "../images/product_3.png"
import Product_4 from "../images/product_4.png"
import Product_Display_1 from "../images/product_display_1.png"
import Product_Display_2 from "../images/product_display_2.png"
import Product_Display_3 from "../images/product_display_3.png"
import Product_Display_4 from "../images/product_display_4.png"
import Checked from "../images/checked.png"
import Human_In_Desk from "../images/humen_in_desk.png";
import Chose_Card_1 from "../images/chose_us_card.svg";
import Chose_Card_2 from "../images/chose_us_card (2).svg";
import Chose_Card_3 from "../images/chose_us_card (3).svg";
import Chose_Card_4 from "../images/chose_us_card (4).svg";
import What_WE_Do from "../images/What_WE_Do.png";
import Client_1 from "../images/client_real_1.jpg";
import Client_2 from "../images/client_real_2.jpg";
import Client_3 from "../images/client_real_3.png"
import Client_4 from "../images/client_real_4.jpg";
import Client_5 from "../images/client_real_5.png";
import Client_6 from "../images/client_real_6.jpg";
import Client_7 from "../images/client_real_7.jpg";
import Client_8 from "../images/client_real_8.jpg";
import Client_9 from "../images/client_real_9.png";
import Client_10 from "../images/client_real_10.png";
import Client_11 from "../images/client_real_11.png";
import Client_12 from "../images/client_real_12.png";
import Client_13 from "../images/client_real_13.png";
import Client_13_1 from "../images/client_real_13 (1).png";
import Client_14 from "../images/client_real_14.png";
import Client_15 from "../images/client_real_15.png";
import Client_16 from "../images/client_real_16.jpg";
import Client_17 from "../images/client_real_17.jpg";
import Client_18 from "../images/client_real_18.jpg";
import Client_19 from "../images/client_real_19.png";
import Client_20 from "../images/client_real_20.png";
import Client_21 from "../images/client_real_21.jpg";
import Client_22 from "../images/client_real_22.jpg";
import Client_23 from "../images/client_real_23.png";
import Blog_Main from "../images/blog_main.png";
import Blog_1 from "../images/blog_1.svg";
import Blog_2 from "../images/blog_2.svg";
import Blog_3 from "../images/blog_3.svg";
import ProductImg_1 from "../images/EduSelf-logo-for-app2.png"
import ProductImg_2 from "../images/EduSelf-Logo-PNG.png"
import ProductImg_3 from "../images/Logo-BizzSelf-Color.png"
import ProductImg_4 from "../images/logofull1.png"
import ProductImg_5 from "../images/eduself-logo3.png"
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// import { FreeMode } from 'swiper/modules';
// import required modules
import { EffectFade, Parallax, Pagination, Navigation, Autoplay, Mousewheel, FreeMode } from "swiper";
import { gsap, Power3 } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
gsap.registerPlugin(ScrollTrigger);

export default function MainComponent_2(props) {
    // const [state, setState] = useState('');
    const [theposition, setTheposition] = useState('');
    const [count, setCount] = useState('');
    const [counting_done, setCounting_done] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [my_swiper, setMy_swiper] = useState('');
    const [my_swiper_lenght, setMy_swiper_lenght] = useState('');
    const [my_swiper_active_index, setMy_swiper_active_index] = useState(0);
    const [index, setIndex] = useState('');
    const counter = useRef(0);
    const progress_define = useRef(0);
    const section_header = useRef(null);
    useEffect(() => {
        props.navbarStyle(false)

        var containers_1 = gsap.utils.toArray(".section_header");
        var containers_2 = gsap.utils.toArray(".service_child");
        var containers_3 = gsap.utils.toArray(".product_content_box");
        var containers_4 = gsap.utils.toArray(".gsap_left_to_right");
        var containers_5 = gsap.utils.toArray(".gsap_right_to_left");

        containers_1.forEach(function (container) {
            gsap.fromTo(container, { visibility: 'hidden', opacity: 0, marginTop: 100 }, {
                visibility: 'visible', opacity: 1, delay: 0, marginTop: 0, ease: Power3, scrollTrigger: {
                    trigger: container,
                }
            })
        })
        containers_2.forEach(function (container) {
            gsap.fromTo(container, { visibility: 'hidden', opacity: 0, y: 100 }, {
                visibility: 'visible', opacity: 1, duration: 0.2, delay: 0, ease: Power3, y: 0, scrollTrigger: {
                    trigger: container,
                }
            })
        })
        containers_3.forEach(function (container) {
            gsap.fromTo(container, { visibility: 'hidden', opacity: 0, y: 100, marginTop: 100 }, {
                visibility: 'visible', opacity: 1, duration: 0.1, delay: 0, ease: Power3, y: 0, marginTop: 0, scrollTrigger: {
                    trigger: container,
                }
            })
        })

        gsap.fromTo("#Platform_1", { visibility: 'hidden', x: -300, marginTop: 100 }, {
            visibility: 'visible', opacity: 1, duration: 0.2, delay: 0, ease: "bounce.out", x: 0, marginTop: 0, scrollTrigger: {
                trigger: "#Platform_1",
            }
        })
        containers_4.forEach(function (container) {

            gsap.fromTo(container, { visibility: 'hidden', x: -300, }, {
                visibility: 'visible', opacity: 1, duration: 0.2, delay: 0, ease: Power3, x: 0, marginTop: 0, scrollTrigger: {
                    trigger: container,
                }
            })
        })

        containers_5.forEach(function (container) {
            gsap.fromTo(container, { visibility: 'hidden', x: 300, }, {
                visibility: 'visible', opacity: 1, duration: 0.2, delay: 0, ease: Power3, x: 0, scrollTrigger: {
                    trigger: container,
                }
            })
        })




        createEvent()
        return () => {
            removeEvent()

        }
    }, [])
    const createEvent = () => {
        window.addEventListener('scroll', listenToScroll_1)
        window.addEventListener('scroll', progress_bar_change)
    }
    const removeEvent = () => {
        window.removeEventListener('scroll', listenToScroll_1)
        window.removeEventListener('scroll', progress_bar_change)
    }



    const listenToScroll_1 = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const why_Chose_us = document.getElementById('why_Chose_us');
        const count = why_Chose_us.querySelectorAll('.count');
        let y = why_Chose_us.offsetTop
        let interval = 50;
        // const height =
        //     document.documentElement.scrollHeight -
        //     document.documentElement.clientHeight
        // const scrolled = winScroll / height
        // const heigh = document.documentElement.scrollHeight
        // const heigh2 = document.documentElement.clientHeight
        if (y - 400 <= winScroll && counting_done === 0 && counter.current === 0) {
            count.forEach((element) => {
                let startValue = parseInt(3 * element.getAttribute('data-val') / 4);
                let endValue = parseInt(element.getAttribute('data-val'));
                // let duration = Math.floor(interval / endValue);
                let duration = 0.0000000001;
                let counter = setInterval(function () {
                    startValue += 1;
                    element.innerText = startValue;
                    if (startValue === endValue) {
                        clearInterval(counter);
                    }
                }, duration)

            })
            setCounting_done(1)
            counter.current = 1;
            // console.log(counting_done)
        }
        setTheposition(winScroll)

    }

    const progress_bar_change = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const why_Chose_us = document.getElementById('WHAT_WE_DO');
        let y = why_Chose_us.offsetTop

        if (y - 300 <= winScroll && progress_define.current == 0) {
            progress_define.current = 1;
            let progress_bar = document.getElementsByClassName('progress-line');
            setTimeout(() => {
                Array.from(progress_bar).forEach(ele => {
                    let width_start = 0;
                    let width_end = Math.floor(ele.getAttribute('data-width'));
                    console.log(width_end);
                    let increase = setInterval(change_style, 0.001);
                    function change_style() {
                        if (width_start >= width_end) {
                            clearInterval(increase);
                            // progress_define.current = 1;
                        }
                        else {
                            width_start++;
                            ele.getElementsByClassName('counter')[0].textContent = width_start;
                            ele.style.width = width_start + "%";
                        }
                    }
                })

            }, 100);
        }
    }

    function Read_Less_More(element) {
        let comment = element.target.closest('.comment')
        let dots = comment.getElementsByClassName('dots')[0]
        let more = comment.getElementsByClassName('more')[0]
        let read_less_more_btn = comment.getElementsByClassName('read_less_more_btn')[0]
        more.classList.toggle("display_more");
        more.classList.toggle("display_none");
        dots.classList.toggle('invisible');
        if (read_less_more_btn.innerText === 'Read More') {
            read_less_more_btn.innerText = 'Read Less';
        }
        else {
            read_less_more_btn.innerText = 'Read More';
        }
        if (read_less_more_btn.innerText === 'Read Less') {
            setTimeout(() => {
                dots.classList.remove('invisible');
                more.classList.add("display_none");
                more.classList.remove("display_more");
                read_less_more_btn.innerText = "Read More"
            }, 5000);
        }

    }
    // Client comment section ends 

    // freequiently ask question section starts
    function hiddenClicker(element) {
        let parent = element.target.closest('.Frequently_ask_box_main')
        let icon = parent.getElementsByClassName('fa-solid')[0]
        let boxs = parent.getElementsByClassName('Frequently_ask_box')[0]
        let main_box = document.getElementsByClassName('Frequently_ask_box')
        if (!boxs.classList.contains('Frequently_ask_box_vissible')) {
            for (let index = 0; index < main_box.length; index++) {
                const element = main_box[index];
                if (element.classList.contains('Frequently_ask_box_vissible')) {
                    element.classList.remove('Frequently_ask_box_vissible')
                    let sub_parent = element.closest('.Frequently_ask_box_main')
                    let icon2 = sub_parent.getElementsByClassName('fa-solid')[0]
                    icon2.classList.remove('fa-circle-xmark')
                    icon2.classList.add('fa-chevron-down')
                }
            }
            boxs.classList.add("Frequently_ask_box_vissible")
            icon.classList.remove('fa-chevron-down')
            icon.classList.add('fa-circle-xmark')
        }
        else {
            for (let index = 0; index < main_box.length; index++) {
                const element = main_box[index];
                if (element.classList.contains('Frequently_ask_box_vissible')) {
                    element.classList.remove('Frequently_ask_box_vissible')
                    let sub_parent = element.closest('.Frequently_ask_box_main')
                    let icon2 = sub_parent.getElementsByClassName('fa-solid')[0]
                    icon2.classList.remove('fa-circle-xmark')
                    icon2.classList.add('fa-chevron-down')
                }
            }
            boxs.classList.remove("Frequently_ask_box_vissible")
            icon.classList.add('fa-chevron-down')
            icon.classList.remove('fa-circle-xmark')
        }

    }
    const slidePrev = (el) => {
        // console.log(el.target)
        if (el.target.classList === "swiper-button-disabled") {

            el.target.classList.remove('swiper-button-disabled')
        }
        if (my_swiper_active_index == 0) {
            // console.log('true')
            el.target.classList.add('swiper-button-disabled')
        }
    }
    const slideNext = (el) => {

        if (el.target.classList === "swiper-button-disabled") {

            el.target.classList.remove('swiper-button-disabled')
        }
        if (my_swiper_active_index == 4) {
            el.target.classList.add('swiper-button-disabled')
        }
    }
    // our producst section starts
    function productsChange(element, id) {
        let all_products = document.getElementsByClassName('all_products');
        for (let index = 0; index < all_products.length; index++) {
            if (index.toString() === id) {
                continue;
            }
            const ele1 = all_products[index];
            ele1.getElementsByClassName('vanila_Swiper_Corner')[0].classList.add('hidden');
            ele1.getElementsByClassName('vanila_Swiper_Corner')[0].classList.remove('block');
            ele1.classList.remove('clicked_product')
            ele1.getElementsByTagName('img')[0].classList.add('grayscale');
            ele1.getElementsByTagName('img')[0].classList.remove('grayscale-0');
        }
        let clicked_product = element.target.closest('.all_products');
        clicked_product.classList.add('clicked_product')
        clicked_product.getElementsByClassName('vanila_Swiper_Corner')[0].classList.remove('hidden');
        clicked_product.getElementsByClassName('vanila_Swiper_Corner')[0].classList.add('block');
        clicked_product.getElementsByTagName('img')[0].classList.add('grayscale-0');
        clicked_product.getElementsByTagName('img')[0].classList.remove('grayscale');

        let change_container = document.getElementsByClassName('change_container');
        for (let index = 0; index < change_container.length; index++) {
            if (index.toString() === id) {
                document.getElementById(id).classList.remove('vanila_products_hidden');
                document.getElementById(id).classList.add('vanila_products_vissible');
                continue;
            }
            const ele2 = change_container[index];
            ele2.classList.add('vanila_products_hidden')
            // ele2.classList.add('hidden')
            ele2.classList.remove('vanila_products_vissible')


        }


    }

    const Product_Content_box = (e) => {
        // console.log()
        if (window.innerWidth >= '768') {
            let product_content_box = document.getElementsByClassName('product_content_box');
            let product_content_box2 = e.target.closest(".product_content_box")
            // if (product_content_box2.classList.contains('md:product_content_box')) {
            // console.log('ehh', product_content_box2)
            // }
            for (let index = 0; index < product_content_box.length; index++) {
                const product_content = product_content_box[index];
                if (product_content.classList.contains('w-[45%]')) {
                    product_content.classList.remove('w-[45%]')
                    product_content.classList.add('w-[13%]')
                    let mini_product_content_box_1 = product_content.getElementsByClassName('mini_product_content_box_1')[0]
                    let mini_product_content_box_2 = product_content.getElementsByClassName('mini_product_content_box_2')[0]
                    let text_content = mini_product_content_box_2.getElementsByClassName('text_content');
                    for (let index = 0; index < text_content.length; index++) {
                        const element = text_content[index];
                        if (element.classList.contains('text_content_anime_on')) {
                            element.classList.remove('text_content_anime_on')
                            element.classList.add('text_content_anime_off')
                        }
                        // console.log(element)
                    }
                    if (mini_product_content_box_1.classList.contains('hidden')) {
                        mini_product_content_box_1.classList.add('block');
                        mini_product_content_box_1.classList.remove('hidden');
                        if (!mini_product_content_box_2.classList.contains('hidden')) {
                            mini_product_content_box_2.classList.add('hidden')
                            mini_product_content_box_2.classList.remove('block')
                        }
                    }
                }
            }

            let product_content_box_2nd = e.target.closest('.product_content_box');
            let mini_product_content_box_1 = product_content_box_2nd.getElementsByClassName('mini_product_content_box_1')[0];
            let mini_product_content_box_2 = product_content_box_2nd.getElementsByClassName('mini_product_content_box_2')[0];
            if (product_content_box_2nd.classList.contains('w-[13%]')) {
                product_content_box_2nd.classList.add('w-[45%]')
                product_content_box_2nd.classList.remove('w-[13%]')
                if (!mini_product_content_box_1.classList.contains('hidden')) {
                    mini_product_content_box_1.classList.add('hidden')
                    mini_product_content_box_1.classList.remove('block')
                    if (mini_product_content_box_2.classList.contains('hidden')) {
                        mini_product_content_box_2.classList.add('block')
                        mini_product_content_box_2.classList.remove('hidden')
                        let text_content = mini_product_content_box_2.getElementsByClassName('text_content');
                        for (let index = 0; index < text_content.length; index++) {
                            setTimeout(() => {
                                const element = text_content[index];
                                if (element.classList.contains('text_content_anime_off')) {
                                    element.classList.add('text_content_anime_on')
                                    element.classList.remove('text_content_anime_off')
                                }
                                // console.log(element)
                            }, 200 * index);

                        }
                    }
                }
            }
        }
    }

    return (
        <>
            <div id='main_component ' className="main_component overflow-hidden p-0 mx-0 " >
                <section id='slider' className='main-banner-two text-white pt-32 md:pt-0  md:mt-0  
                overflow-hidden h-[44rem] min-[1100px]:h-[58rem]   ' style={{
                        backgroundImage: "linear-gradient(to bottom, #420008, #5f0818, #7e1425, #9e2032, #c02d40, #d03b60, #db4c80, #e260a1, #c37fc7, #a398d6, #95aad3, #a0b6c6)",
                    }} >
                    <div className='flex flex-col min-[1100px]:flex-row   relative bullet_svg'>
                        <Swiper
                            speed={1500}
                            grabCursor={true}
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            effect={'fade'}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                                dynamicBullets: true,
                                renderBullet: function (index, className) {
                                    return '<span class="' + className + ' swiper-pagination-bullet--svg-animation"><svg width="26" height="26" viewBox="0 0 28 28"><circle class="svg__circle" cx="14" cy="14" r="12" fill="none" stroke-width="2"></circle><circle class="svg__circle-inner" cx="14" cy="14" r="6" stroke-width="3"></circle></svg></span>';
                                },
                            }}
                            navigation={{
                                nextEl: '.next',
                                prevEl: '.prev',
                            }}

                            onSlideChange={(ev) => {

                                setMy_swiper_active_index(ev.activeIndex)

                            }}
                            onInit={(ev) => {
                                setMy_swiper(ev)
                                setMy_swiper_lenght(ev.slides.length)
                                setMy_swiper_active_index(ev.activeIndex)

                            }}
                            modules={[Pagination, Navigation, EffectFade, Autoplay]}
                            className='mySwiper'>
                            <SwiperSlide>
                                <div className='grid grid-cols-1 min-[1100px]:grid-cols-2 relative mx-auto max-w-[1920px]  md:pt-28  min-[1100px]:px-16 min-[1330px]:p-28  2xl:px-52'>
                                    <div className='pt-28 md:mx-0 mx-auto text-center min-[1100px]:text-left '>
                                        <h1 className='fadeinleft text-3xl min-[1100px]:text-5xl min-[1330px]:text-[4rem] font-bold my-auto leading-snug mb-6 '>Welcome to Digital Excellence.  </h1>
                                        <p className='fadeinright text-base leading-relaxed px-12 md:px-16 min-[1100px]:px-0 min-[1100px]:pe-5'>At WebShinez Technology, we're dedicated to driving your business forward with cutting-edge software solutions and comprehensive ERP services. Our mission is simple: to empower your organization with the tools and technologies needed to thrive in today's digital landscape. </p>
                                        <div className='fadeindown flex gap-2 mt-6 text-lg justify-center min-[1100px]:justify-start '>

                                            <span className="vanila_btn_hover_3 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/aboutUs'}>About Us</Link></span>
                                            <span className="vanila_btn_hover_1 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/pages/contactUs'}>Contact Us</Link></span>
                                        </div>
                                        {/* <div className='fadeindown mx-auto  mt-6  z-10 text-center '>
                                            <div className='flex gap-8 justify-center min-[1100px]:justify-start '>

                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg prev swiper-button-disabled' onClick={(el) => { slidePrev(el) }}>Prev</span>
                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg next' onClick={(el) => { slideNext(el) }}>Next</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='hidden m-auto min-[1100px]:flex flex-row justify-center items-center relative top-[120%] w-[4rem] min-[300px]:top-[125%] min-[300px]:w-[6rem] min-[400px]:top-[35.5rem] min-[400px]:w-[6rem] min-[600px]:top-[33rem] sm:top-[36rem] sm:w-[10rem] md:top-[48rem] md:w-[12rem] right-0 left-0  min-[1100px]:w-[25rem] min-[1100px]:top-[27%] min-[1100px]:right-[8%] min-[1100px]:left-auto min-[1220px]:top-[27%] min-[1220px]:left-auto  min-[1220px]:right-[5%]  min-[1330px]:right-[2%] min-[1330px]:left-auto min-[1330px]:top-[30%] min-[1410px]:right-[-6%] min-[1510px]:right-[-8%] min-[1660px]:right-[-13%] min-[1870px]:right-[-6%]   2xl:top-[45%]  z-10  '>
                                        <img className='h-auto w-auto fadeinright  ' src={Slide_Anime_Img_1} alt="" />
                                        {/* <img className='h-auto w-auto fadeinleft ' src={Slide_Anime_Img_2} alt="" /> */}
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='grid grid-cols-1 min-[1100px]:grid-cols-2 relative mx-auto max-w-[1920px]  md:pt-28  min-[1100px]:px-16 min-[1330px]:p-28  2xl:px-52'>
                                    <div className='pt-28 md:mx-0 mx-auto text-center min-[1100px]:text-left '>
                                        <h1 className='fadeinleft text-3xl min-[1100px]:text-5xl min-[1330px]:text-[4rem] font-bold my-auto leading-snug mb-6 '>Experience The Power  Solutions</h1>
                                        <p className='fadeinright text-base leading-relaxed px-12 md:px-16 min-[1100px]:px-0 min-[1100px]:pe-5'>Trust in our expertise to revolutionize your business landscape, unlocking its full potential and propelling it towards sustainable prosperity. Experience the power of synergy with our comprehensive Software solutions.</p>
                                        <div className='fadeindown flex gap-2 mt-6 text-lg justify-center min-[1100px]:justify-start '>

                                            <span className="vanila_btn_hover_3 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/aboutUs'}>About Us</Link></span>
                                            <span className="vanila_btn_hover_1 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/pages/contactUs'}>Contact Us</Link></span>
                                        </div>
                                        {/* <div className='fadeindown mx-auto  mt-6  z-10 text-center '>
                                            <div className='flex gap-8 justify-center min-[1100px]:justify-start '>

                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg prev swiper-button-disabled' onClick={(el) => { slidePrev(el) }}>Prev</span>
                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg next' onClick={(el) => { slideNext(el) }}>Next</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='hidden m-auto min-[1100px]:flex flex-row justify-center items-center relative top-[120%] w-[4rem] min-[300px]:top-[125%] min-[300px]:w-[6rem] min-[400px]:top-[35.5rem] min-[400px]:w-[6rem] min-[600px]:top-[33rem] sm:top-[36rem] sm:w-[10rem] md:top-[48rem] md:w-[12rem] right-0 left-0  min-[1100px]:w-[25rem] min-[1100px]:top-[27%] min-[1100px]:right-[8%] min-[1100px]:left-auto min-[1220px]:top-[27%] min-[1220px]:left-auto  min-[1220px]:right-[5%] min-[1330px]:right-[2%] min-[1330px]:left-auto min-[1330px]:top-[42%] min-[1410px]:right-[-6%] min-[1510px]:right-[-8%] min-[1660px]:right-[-13%] min-[1870px]:right-[-6%]   2xl:top-[45%]  z-10  '>
                                        <img className='h-auto w-auto fadeinright  ' src={Slide_Anime_Img_1} alt="" />
                                        {/* <img className='h-auto w-auto fadeinleft ' src={Slide_Anime_Img_2} alt="" /> */}
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='grid grid-cols-1 min-[1100px]:grid-cols-2 relative mx-auto max-w-[1920px]  md:pt-28  min-[1100px]:px-16 min-[1330px]:p-28  2xl:px-52'>
                                    <div className='pt-28 md:mx-0 mx-auto text-center min-[1100px]:text-left '>
                                        <h1 className='fadeinleft text-3xl min-[1100px]:text-5xl min-[1330px]:text-[4rem] font-bold my-auto leading-snug mb-6 '>The Power of Synergy</h1>
                                        <p className='fadeinright text-base leading-relaxed px-12 md:px-16 min-[1100px]:px-0 min-[1100px]:pe-5'> Empowering Education, Simplifying Administration: Your School's Success Starts Here. With our intuitive management software, streamline operations, enhance communication, and elevate learning experiences.</p>
                                        <div className='fadeindown flex gap-2 mt-6 text-lg justify-center min-[1100px]:justify-start '>

                                            <span className="vanila_btn_hover_3 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/aboutUs'}>About Us</Link></span>
                                            <span className="vanila_btn_hover_1 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/pages/contactUs'}>Contact Us</Link></span>
                                        </div>
                                        {/* <div className='fadeindown mx-auto  mt-6  z-10 text-center '>
                                            <div className='flex gap-8 justify-center min-[1100px]:justify-start '>

                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg prev swiper-button-disabled' onClick={(el) => { slidePrev(el) }}>Prev</span>
                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg next' onClick={(el) => { slideNext(el) }}>Next</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='hidden m-auto min-[1100px]:flex flex-row justify-center items-center relative top-[120%] w-[4rem] min-[300px]:top-[125%] min-[300px]:w-[6rem] min-[400px]:top-[35.5rem] min-[400px]:w-[6rem] min-[600px]:top-[33rem] sm:top-[36rem] sm:w-[10rem] md:top-[48rem] md:w-[12rem] right-0 left-0  min-[1100px]:w-[25rem] min-[1100px]:top-[27%] min-[1100px]:right-[8%] min-[1100px]:left-auto min-[1220px]:top-[27%] min-[1220px]:left-auto  min-[1220px]:right-[5%] min-[1330px]:right-[2%] min-[1330px]:left-auto min-[1330px]:top-[42%] min-[1410px]:right-[-6%] min-[1510px]:right-[-8%] min-[1660px]:right-[-13%] min-[1870px]:right-[-6%]   2xl:top-[45%]  z-10  '>
                                        <img className='h-auto w-auto fadeinright  ' src={Slide_Anime_Img_1} alt="" />
                                        {/* <img className='h-auto w-auto fadeinleft ' src={Slide_Anime_Img_2} alt="" /> */}
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='grid grid-cols-1 min-[1100px]:grid-cols-2 relative mx-auto max-w-[1920px]  md:pt-28  min-[1100px]:px-16 min-[1330px]:p-28  2xl:px-52'>
                                    <div className='pt-28 md:mx-0 mx-auto text-center min-[1100px]:text-left '>
                                        <h1 className='fadeinleft text-3xl min-[1100px]:text-5xl min-[1330px]:text-[4rem] font-bold my-auto leading-snug mb-6 '>let's shape a brighter Future.</h1>
                                        <p className='fadeinright text-base leading-relaxed px-12 md:px-16 min-[1100px]:px-0 min-[1100px]:pe-5'> Embrace efficiency, embrace innovation, and watch your Madrasha thrive with our cutting-edge technology. Join the countless educators, administrators, and parents who trust our platform to optimize every aspect of the educational journey. </p>
                                        <div className='fadeindown flex gap-2 mt-6 text-lg justify-center min-[1100px]:justify-start '>

                                            <span className="vanila_btn_hover_3 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/aboutUs'}>About Us</Link></span>
                                            <span className="vanila_btn_hover_1 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/pages/contactUs'}>Contact Us</Link></span>
                                        </div>
                                        {/* <div className='fadeindown mx-auto  mt-6  z-10 text-center '>
                                            <div className='flex gap-8 justify-center min-[1100px]:justify-start '>

                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg prev swiper-button-disabled' onClick={(el) => { slidePrev(el) }}>Prev</span>
                                                <span className='px-4 py-2 vanila_btn_hover_3 rounded-lg next' onClick={(el) => { slideNext(el) }}>Next</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='hidden m-auto min-[1100px]:flex flex-row justify-center items-center relative top-[120%] w-[4rem] min-[300px]:top-[125%] min-[300px]:w-[6rem] min-[400px]:top-[35.5rem] min-[400px]:w-[6rem] min-[600px]:top-[33rem] sm:top-[36rem] sm:w-[10rem] md:top-[48rem] md:w-[12rem] right-0 left-0  min-[1100px]:w-[25rem] min-[1100px]:top-[27%] min-[1100px]:right-[8%] min-[1100px]:left-auto min-[1220px]:top-[27%] min-[1220px]:left-auto  min-[1220px]:right-[5%] min-[1330px]:right-[2%] min-[1330px]:left-auto min-[1330px]:top-[42%] min-[1410px]:right-[-6%] min-[1510px]:right-[-8%] min-[1660px]:right-[-13%] min-[1870px]:right-[-6%]   2xl:top-[45%]  z-10  '>
                                        <img className='h-auto w-auto fadeinright  ' src={Slide_Anime_Img_1} alt="" />
                                        {/* <img className='h-auto w-auto fadeinleft ' src={Slide_Anime_Img_2} alt="" /> */}
                                    </div>

                                </div>
                            </SwiperSlide>
                            {/* <SwiperSlide>
                                <div className='grid grid-cols-1 min-[1100px]:grid-cols-2 relative mx-auto max-w-[1920px]  md:pt-28  min-[1100px]:px-16 min-[1330px]:p-28  2xl:px-52'>
                                    <div className='pt-28 md:mx-0 mx-auto text-center min-[1100px]:text-left '>
                                        <h1 className='fadeinleft text-3xl min-[1100px]:text-2xl min-[1330px]:text-7xl font-bold my-auto leading-snug mb-6 '>Big Data And Analytics</h1>
                                        <p className='fadeinright text-base leading-relaxed px-12 md:px-16 min-[1100px]:px-0 min-[1100px]:pe-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                                        <div className='fadeindown flex gap-2 mt-6 text-lg justify-center min-[1100px]:justify-start '>

                                            <span className="vanila_btn_hover_3 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/aboutUs'}>About Us</Link></span>
                                            <span className="vanila_btn_hover_1 cursor-pointer text-white rounded-lg px-8 py-2"><Link to={'/contactUs'}>Contact Us</Link></span>
                                        </div>
                                    </div>
                                    <div className='hidden m-auto min-[1100px]:flex flex-row justify-center items-center absolute top-[120%] w-[4rem] min-[300px]:top-[125%] min-[300px]:w-[6rem] min-[400px]:top-[35.5rem] min-[400px]:w-[6rem] min-[600px]:top-[33rem] sm:top-[36rem] sm:w-[10rem] md:top-[48rem] md:w-[12rem] right-0 left-0 min-[1100px]:left-auto min-[1100px]:w-auto min-[1100px]:top-[69%] min-[1100px]:right-[15%] min-[1330px]:right-[17%] min-[1330px]:top-[55%]  2xl:top-[65%] 2xl:right-[16%] z-10  '>
                                        <img className='h-auto w-auto fadeinright  ' src={Slide_Anime_Img_1} alt="" />
                                        <img className='h-auto w-auto fadeinleft ' src={Slide_Anime_Img_2} alt="" />
                                    </div>

                                </div>
                            </SwiperSlide> */}

                            <div className="swiper-pagination swiper-pagination-bullets swiper-pagination-vertical px-8 !absolute  !top-[60%] !left-[60%] min-[300px]:!left-[68%] min-[400px]:!left-[74%]  min-[540px]:!left-[80%] md:!top-[50%] md:!left-[87%] min-[1330px]:!left-[90%] 2xl:!left-[90%] !rotate-90 "></div>

                        </Swiper>
                        {/* <div className=' mx-auto xs:w-[20rem] sm:w-[30rem] md:w-[37rem] min-[1100px]:w-[40rem] min-[1330px]:w-[50rem] min-[1100px]:mx-0 min-[1100px]:absolute   min-[1100px]:top-[15%]  min-[1330px]:top-[5%]  min-[1100px]:right-[5%] '>
            <img className=' animate-spin-slow h-auto w-auto origin-center ' src={Slider_Circle} alt="" />
        </div> */}
                        <div className='hidden min-[1100px]:block mx-auto xs:w-[20rem] sm:w-[30rem] md:w-[37rem] min-[1100px]:w-[40rem] min-[1330px]:w-[50rem] 2xl:w-[55rem] min-[1100px]:mx-0 min-[1100px]:absolute   min-[1100px]:top-[15%]  min-[1330px]:top-[6%]  min-[1100px]:right-[5%] min-[1330px]:right-[0.5%] min-[1860px]:2xl:left-[50%] '>
                            <img className=' animate-spin-slow h-auto w-auto origin-center ' src={Slider_Circle} alt="" />
                        </div>
                    </div>
                    <div class="creative-shape">
                        <img src={Slider_Shape} alt="main-image" />
                    </div>
                </section >


                {/* services section starts  */}

                <section id="services" className='py-[5rem] md:py-[100px] bg-white px-6  mx-auto max-w-[1920px] lg:px-16  xl:px-28 2xl:px-[13rem]  ' style={{ background: "white" }} >
                    <h1 className='section_header mb-8 md:mb-16' ref={section_header}>OUR SERVICES</h1>
                    <div className="services grid text-left grid-cols-1 gap-8   md:grid-cols-2 lg:grid-cols-3   lg:gap-8  2xl:gap-8">

                        <div className="service_child group duration-[600ms]  ease-in-out transition-all hover:-translate-y-6 bg-white p-8 rounded-tl-[1.5rem] rounded-br-[1.5rem]   z-10 relative before:duration-[600ms] before:ease-in-out before:transition-all before:-z-10 before:content-normal before:absolute before:bottom-0 before:left-0 before:right-0 before:h-0 before:bg-[--Primary-color] before:w-full hover:before:h-full hover:before:bg-[--Primary-color]" style={{ boxShadow: "2px 1px 30px -10px rgba(79, 79, 79, 0.39)" }}>
                            <div className='flex items-center gap-3 mb-4 ' >
                                <span className='text-xl xl:text-2xl text-web-color bg-[#b1c7d7] duration-[600ms] ease-in-out transition-all   group-hover:rotate-[360deg] group-hover:text-[--Secondary-color] group-hover:bg-white px-[0.6rem] py-[0.3rem] rounded-full'><i class="fa-solid fa-globe"></i></span>
                                <h5 className="font-bold  text-lg xl:text-xl text-[--Deem-secondary-color] duration-[600ms] ease-in-out transition-all group-hover:text-white">Website Development</h5>
                            </div>
                            <p className='text-[#737b9a] duration-[600ms] ease-in-out transition-all text-[--Secondary-color] group-hover:text-white'> We develop dynamic responsive website, e-commerce stores, different kinds of web portal and other web application with easy to use admin panel to edit images and other contents. Our scripts are SEO friendly which helps your website to rank higher.</p>
                            <p className='font-bold mt-4 duration-[600ms]  ease-in-out transition-all cursor-pointer text-[--Primary-color] group-hover:text-white group-hover:text-base'><Link to={'/services/1'}>Read More</Link><span><i class="fa-solid fa-arrow-right-long"></i></span></p>
                        </div>
                        <div className=" service_child group duration-[600ms]  ease-in-out transition-all hover:-translate-y-6 bg-white p-8 rounded-tl-[1.5rem] rounded-br-[1.5rem] z-10 relative before:duration-[600ms] before:ease-in-out before:transition-all before:-z-10 before:content-normal before:absolute before:bottom-0 before:left-0 before:right-0 before:h-0 before:bg-[--Primary-color] before:w-full hover:before:h-full hover:before:bg-[--Primary-color]" style={{ boxShadow: "2px 1px 30px -10px rgba(79, 79, 79, 0.39)" }}>
                            <div className='flex items-center gap-3 mb-4'>
                                <span className='text-xl xl:text-2xl text-web-color bg-[#b1c7d7] duration-[600ms] ease-in-out transition-all  group-hover:rotate-[360deg] group-hover:text-[--Secondary-color] group-hover:bg-white px-[0.6rem] py-[0.3rem] rounded-full'><i class="fa-solid fa-bag-shopping"></i></span>
                                <h5 className="font-bold  text-lg xl:text-xl text-[--Deem-secondary-color] duration-[600ms] ease-in-out transition-all group-hover:text-white">Digital Marketing</h5>
                            </div>
                            <p className='text-[#737b9a] duration-[600ms] ease-in-out transition-all text-[--Secondary-color] group-hover:text-white'>WebShinez Technologies offers comprehensive online marketing solutions focused on enhancing your digital presence, increasing conversions, and nurturing long-term engagement for your business. Our services include SMS marketing, email marketing, SEO, and a wide range of PPC strategies tailored to your needs.</p>
                            <p className='font-bold mt-4 duration-[600ms]  ease-in-out transition-all cursor-pointer text-[--Primary-color] group-hover:text-white group-hover:text-base'><Link to={'/services/2'}>Read More</Link><span><i class="fa-solid fa-arrow-right-long"></i></span></p>
                        </div>
                        <div className="service_child group duration-[600ms]  ease-in-out transition-all hover:-translate-y-6 bg-white p-8 rounded-tl-[1.5rem] rounded-br-[1.5rem] z-10 relative before:duration-[600ms] before:ease-in-out before:transition-all before:-z-10 before:content-normal before:absolute before:bottom-0 before:left-0 before:right-0 before:h-0 before:bg-[--Primary-color] before:w-full hover:before:h-full hover:before:bg-[--Primary-color]" style={{ boxShadow: "2px 1px 30px -10px rgba(79, 79, 79, 0.39)" }}>
                            <div className='flex items-center gap-3 mb-4'>
                                <span className='text-xl xl:text-2xl text-web-color bg-[#b1c7d7] duration-[600ms] ease-in-out transition-all  group-hover:rotate-[360deg] group-hover:text-[--Secondary-color] group-hover:bg-white px-[0.6rem] py-[0.3rem] rounded-full'><i class="fa-solid fa-registered"></i></span>
                                <h5 className="font-bold  text-lg xl:text-xl text-[--Deem-secondary-color] duration-[600ms] ease-in-out transition-all group-hover:text-white">Domain Registration & Hosting</h5>
                            </div>
                            <p className='text-[#737b9a] duration-[600ms] ease-in-out transition-all text-[--Secondary-color] group-hover:text-white'>We provide top-tier Domain Registration & Hosting services with uncompromising security. Our prices are highly competitive, catering to both corporate and non-corporate needs with affordable packages.</p>
                            <p className='font-bold mt-4 duration-[600ms]  ease-in-out transition-all cursor-pointer text-[--Primary-color] group-hover:text-white group-hover:text-base'><Link to={'/services/3'}>Read More</Link><span><i class="fa-solid fa-arrow-right-long"></i></span></p>
                        </div>
                        <div className="service_child group duration-[600ms]  ease-in-out transition-all hover:-translate-y-6 bg-white p-8 rounded-tl-[1.5rem] rounded-br-[1.5rem] z-10 relative before:duration-[600ms] before:ease-in-out before:transition-all before:-z-10 before:content-normal before:absolute before:bottom-0 before:left-0 before:right-0 before:h-0 before:bg-[--Primary-color] before:w-full hover:before:h-full hover:before:bg-[--Primary-color]" style={{ boxShadow: "2px 1px 30px -10px rgba(79, 79, 79, 0.39)" }}>
                            <div className='flex items-center gap-3 mb-4'>
                                <span className='text-xl xl:text-2xl text-web-color bg-[#b1c7d7] duration-[600ms] ease-in-out transition-all  group-hover:rotate-[360deg] group-hover:text-[--Secondary-color] group-hover:bg-white px-[0.6rem] py-[0.3rem] rounded-full'><i class="fa-brands fa-squarespace"></i></span>
                                <h5 className="font-bold  text-lg xl:text-xl text-[--Deem-secondary-color] duration-[600ms] ease-in-out transition-all group-hover:text-white">Software Development</h5>
                            </div>
                            <p className='text-[#737b9a] duration-[600ms] ease-in-out transition-all text-[--Secondary-color] group-hover:text-white'>Welcome to Webshinez Technologies, a leading software development company based in Bangladesh. Specializing in crafting exceptional solutions for mobile, web, and Windows platforms, we excel in developing a variety of software applications. Our expertise spans Hotel ERP, HRM, Accounting, Payroll, Garments...</p>
                            <p className='font-bold mt-4 duration-[600ms]  ease-in-out transition-all cursor-pointer text-[--Primary-color] group-hover:text-white group-hover:text-base'><Link to={'/services/4'}>Read More</Link><span><i class="fa-solid fa-arrow-right-long"></i></span></p>
                        </div>
                        <div className="service_child group duration-[600ms]  ease-in-out transition-all hover:-translate-y-6 bg-white p-8 rounded-tl-[1.5rem] rounded-br-[1.5rem] z-10 relative before:duration-[600ms] before:ease-in-out before:transition-all before:-z-10 before:content-normal before:absolute before:bottom-0 before:left-0 before:right-0 before:h-0 before:bg-[--Primary-color] before:w-full hover:before:h-full hover:before:bg-[--Primary-color]" style={{ boxShadow: "2px 1px 30px -10px rgba(79, 79, 79, 0.39)" }}>
                            <div className='flex items-center gap-3 mb-4'>
                                <span className='text-xl xl:text-2xl text-web-color bg-[#b1c7d7] duration-[600ms] ease-in-out transition-all  group-hover:rotate-[360deg] group-hover:text-[--Secondary-color] group-hover:bg-white px-[0.6rem] py-[0.3rem] rounded-full'><i class="fa-brands fa-adn"></i></span>
                                <h5 className="font-bold  text-lg xl:text-xl text-[--Deem-secondary-color] duration-[600ms] ease-in-out transition-all group-hover:text-white">Mobile App Development</h5>
                            </div>
                            <p className='text-[#737b9a] duration-[600ms] ease-in-out transition-all text-[--Secondary-color] group-hover:text-white'>As a trusted Mobile App Development Company, we specialize in delivering professional Android app development services. Our seasoned team conducts thorough research and analysis to precisely meet your needs and surpass your expectations. </p>
                            <p className='font-bold mt-4 duration-[600ms]  ease-in-out transition-all cursor-pointer text-[--Primary-color] group-hover:text-white group-hover:text-base'><Link to={'/services/5'}>Read More</Link><span><i class="fa-solid fa-arrow-right-long"></i></span></p>
                        </div>
                        <div className="service_child group duration-[600ms]  ease-in-out transition-all hover:-translate-y-6 bg-white p-8 rounded-tl-[1.5rem] rounded-br-[1.5rem]  z-10 relative before:duration-[600ms] before:ease-in-out before:transition-all before:-z-10 before:content-normal before:absolute before:bottom-0 before:left-0 before:right-0 before:h-0 before:bg-[--Primary-color] before:w-full hover:before:h-full hover:before:bg-[--Primary-color]" style={{ boxShadow: "2px 1px 30px -10px rgba(79, 79, 79, 0.39)" }}>
                            <div className='flex items-center gap-3 mb-4'>
                                <span className='text-xl xl:text-2xl text-web-color bg-[#b1c7d7] duration-[600ms] ease-in-out transition-all  group-hover:rotate-[360deg] group-hover:text-[--Secondary-color] group-hover:bg-white px-[0.6rem] py-[0.3rem] rounded-full'><i class="fa-solid fa-briefcase"></i></span>
                                <h5 className="font-bold  text-lg xl:text-xl text-[--Deem-secondary-color] duration-[600ms] ease-in-out transition-all group-hover:text-white">e-Commerce Development</h5>
                            </div>
                            <p className='text-[#737b9a] duration-[600ms] ease-in-out transition-all text-[--Secondary-color] group-hover:text-white'>The surge in eCommerce Websites' popularity in Bangladesh is noticeable day by day. Transitioning your local shop or business into an online platform is made effortless with an eCommerce Website, leading to rapid boosts in sales and revenue. </p>
                            <p className='font-bold mt-4 duration-[600ms]  ease-in-out transition-all cursor-pointer text-[--Primary-color] group-hover:text-white group-hover:text-base'><Link to={'/services/6'}>Read More</Link><span><i class="fa-solid fa-arrow-right-long"></i></span></p>
                        </div>
                    </div>
                </section >

                {/* services section ends  */}
                {/* our products starts */}
                <section id='our_products' className=' bg-[#EEF0F8] py-[5rem] md:py-[100px] text-white md:text-slate-50'>
                    <div className='mx-auto max-w-[1920px] lg:px-16  xl:px-28 2xl:px-[13rem]'>
                        <h1 className='section_header  mb-8 md:mb-16' ref={section_header}>Our Products</h1>
                        <div className='flex gap-8 h-auto  md:h-[20rem]'>
                            <div id='Platform_1' className='hidden lg:basis-1/6 lg:block  h-[18rem] w-[12rem] col-span-2' >
                                <img className='h-full w-auto' src={Platform_1} alt="" />
                            </div>
                            <div className=' lg:basis-5/6 flex flex-col mx-8 md:mx-0 md:flex-row justify-center lg:justify-normal gap-8'>
                                <div className="product_content_box text-center  p-6 bg-[#ef3e53] text-white max-[768px]:w-[100%]  w-[45%] rounded-[2rem] shadow-[0_-6px_0_#630618]" onClick={(e) => { Product_Content_box(e) }}>
                                    <div className='mini_product_content_box_1  max-[768px]:hidden  hidden '>
                                        <h1 className='font-bold text-4xl text-center ' >
                                            <img className='h-16 w-16 mx-auto' src={ProductImg_3} />
                                        </h1>
                                        <h1 className='font-semibold text-white text-2xl mx-auto mt-3' style={{ writingMode: 'vertical-rl' }} >Business <br /> Solution</h1>
                                    </div>
                                    <div className=' text-left max-[768px]:block hidden   px-4'>
                                        <h1 className=' font-bold text-7xl ' >
                                            <img className=' mx-auto w-72 md:w-auto' src={ProductImg_4} />
                                        </h1>
                                        <div className='mt-8'>
                                            <h1 className=' font-semibold  text-2xl mb-4' >Business Solution</h1>
                                            <p className=' text-lg text-white'>Rely on our proficiency to transform your business terrain, unleashing its complete capabilities and driving it towards enduring success.</p>
                                        </div>
                                    </div>

                                    <div className='mini_product_content_box_2 text-left  max-[768px]:hidden block   px-4'>
                                        <h1 className='text_content text_content_anime_on font-bold text-7xl ' >
                                            <img className=' mx-auto w-48' src={ProductImg_4} /></h1>
                                        <div className='mt-8'>
                                            <h1 className='text_content text_content_anime_on font-semibold  text-2xl mb-4' >Business Solution</h1>
                                            <p className='text_content text_content_anime_on text-lg text-white'>Rely on our proficiency to transform your business terrain, unleashing its complete capabilities and driving it towards enduring success.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="product_content_box text-left md:text-center p-6 text-white bg-[#ef3e53] max-[768px]:w-[100%] w-[13%] rounded-[2rem] shadow-[0_-6px_0_#630618]" onClick={(e) => { Product_Content_box(e) }}>
                                    <div className='mini_product_content_box_1  max-[768px]:hidden  block '>
                                        <h1 className=' font-bold  text-4xl  ' >
                                            <img className='h-16 w-16 mx-auto' src={ProductImg_1} />
                                        </h1>
                                        <h1 className='font-semibold text-white text-2xl mx-4 md:mx-auto md:mt-3  [writing-mode:horizontal-tb] md:[writing-mode:vertical-rl] ' >School <br />Management  </h1>
                                    </div>
                                    <div className=' text-left max-[768px]:block hidden   px-4'>
                                        <h1 className=' font-bold text-7xl ' >
                                            <img className='mx-auto w-72 md:w-auto' src={ProductImg_5} />

                                        </h1>
                                        <div className='mt-8'>
                                            <h1 className=' font-semibold  text-2xl mb-4' >School Management</h1>
                                            <p className=' text-lg text-white'>Enabling Education, Streamlining Administration: Your School's Triumph Begins Now. With our user-friendly management software, streamline processes, improve communication, and enrich educational journeys. From monitoring attendance to managing results, we offer holistic solutions customized to meet your institution's requirements.</p>
                                        </div>
                                    </div>
                                    <div className='mini_product_content_box_2 text-left max-[768px]:hidden  hidden   px-4'>
                                        <h1 className='text_content text_content_anime_off font-bold text-7xl  max-[768px]:hidden' >
                                            <img className='w-48 mx-auto' src={ProductImg_5} />
                                        </h1>
                                        <div className='mt-8'>
                                            <h1 className='text_content text_content_anime_off font-semibold  text-2xl mb-4 max-[768px]:hidden' >School Management</h1>
                                            <p className='text_content text_content_anime_off text-lg text-white max-[768px]:hidden'>Enabling Education, Streamlining Administration: Your School's Triumph Begins Now. With our user-friendly management software.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="product_content_box text-left md:text-center p-6 text-white bg-[#ef3e53] max-[768px]:w-[100%] w-[13%] rounded-[2rem] shadow-[0_-6px_0_#630618]" onClick={(e) => { Product_Content_box(e) }}>
                                    <div className='mini_product_content_box_1  max-[768px]:hidden  block '>
                                        <h1 className=' font-bold  text-4xl  ' >
                                            <img className='h-16 w-16 mx-auto' src={ProductImg_1} />
                                        </h1>
                                        <h1 className='font-semibold text-white text-2xl mx-4 md:mx-auto md:mt-3   [writing-mode:horizontal-tb] md:[writing-mode:vertical-rl] ' >Madrasha <br /> Management</h1>
                                    </div>
                                    <div className=' text-left max-[768px]:block hidden   px-4'>
                                        <h1 className=' font-bold text-7xl ' >
                                            <img className='mx-auto w-72 md:w-auto' src={ProductImg_5} />

                                        </h1>
                                        <div className='mt-8'>
                                            <h1 className=' font-semibold  text-2xl mb-4' >Madrasha Management</h1>
                                            <p className=' text-lg text-white'>Embrace effectiveness, embrace ingenuity, and witness your Madrasha flourish with our state-of-the-art technology.</p>
                                        </div>
                                    </div>
                                    <div className='mini_product_content_box_2 text-left max-[768px]:hidden  hidden   px-4'>
                                        <h1 className='text_content text_content_anime_off font-bold text-7xl  max-[768px]:hidden' >
                                            <img className='w-48 mx-auto' src={ProductImg_5} />
                                        </h1>
                                        <div className='mt-8'>
                                            <h1 className='text_content text_content_anime_off font-semibold  text-2xl mb-4 max-[768px]:hidden' >Madrasha Management</h1>
                                            <p className='text_content text_content_anime_off text-lg text-white max-[768px]:hidden'>Embrace effectiveness, embrace ingenuity, and witness your Madrasha flourish with our state-of-the-art technology.</p>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="product_content_box text-left md:text-center p-6 text-white bg-[#ef3e53] max-[768px]:w-[100%] w-[13%] rounded-[2rem] shadow-[0_-6px_0_#630618]" onClick={(e) => { Product_Content_box(e) }}>
                                    <div className='mini_product_content_box_1 max-[768px]:hidden  block '>
                                        <h1 className=' font-bold text-4xl  ' >04.</h1>
                                        <h1 className='font-semibold text-white text-2xl mx-4 md:mx-auto md:mt-3 [writing-mode:horizontal-tb] md:[writing-mode:vertical-lr] ' >Hack Protection</h1>
                                    </div>

                                    <div className=' text-left max-[768px]:block hidden   px-4'>
                                        <h1 className=' font-bold text-7xl ' >04.</h1>
                                        <div className='mt-8'>
                                            <h1 className=' font-semibold  text-2xl mb-4' >Hack Protection</h1>
                                            <p className=' text-lg text-white'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quae recusandae provident dolor natus libero?</p>
                                        </div>
                                    </div>
                                    <div className='mini_product_content_box_2 text-left  max-[768px]:hidden hidden   px-4'>
                                        <h1 className='text_content text_content_anime_off font-bold text-7xl  max-[768px]:hidden' >04.</h1>
                                        <div className='mt-8'>
                                            <h1 className='text_content text_content_anime_off font-semibold  text-2xl mb-4 max-[768px]:hidden' >Hack Protection</h1>
                                            <p className='text_content text_content_anime_off text-lg text-white max-[768px]:hidden'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quae recusandae provident dolor natus libero?</p>
                                        </div>
                                    </div>
                                </div> */}


                            </div>

                        </div>
                    </div>
                </section >

                {/* our products ends */}
                {/* Client Comment section starts  */}
                <section id="client_Comments" className='text-center '>
                    <div id='first_child_client_Comments' className='child_client_Comments py-[5rem] md:pt-[100px] pb-[30rem] min-[400px]:pb-[20rem] 2xl:pb-[20rem] h-[26rem] sm:h-[21rem] md:h-[20rem] lg:h-[22rem] 2xl:h-[23.5rem] px-8   lg:px-16  2xl:px-[15%] '>
                        <h1 className='text-white section_header mb-6  mx-auto  '>Voices of Success </h1>
                        <p className='text-white font-bold text-lg   mx-auto w-auto sm:w-11/12 lg:w-7/12'>Real Stories, Real Results
                            Discover How Our Solutions Are Empowering Businesses Like Yours to Thrive</p>
                    </div>
                    <div className="child_client_Comments   bg-[--Secondary-Light-color] h-[19rem] sm:h-[22rem]">
                        <div className='without_bullet_svg sub_child_client_Comments w-full relative -top-[10rem] lg:-top-[10rem]    '>
                            <div className=' mx-auto max-w-[1920px] lg:px-16  xl:px-28 2xl:px-[13rem]'>
                                <Swiper

                                    slidesPerView={1}
                                    spaceBetween={8}
                                    breakpoints={{
                                        827: {
                                            slidesPerView: 2,
                                        },
                                        1253: {
                                            // inline-size: 768,
                                            slidesPerView: 2,
                                            spaceBetween: 15,
                                        },
                                        1335: {
                                            // inline-size: 1024,
                                            slidesPerView: 3,
                                            spaceBetween: 15,

                                        },
                                        // 1:{
                                        //     slidesPerView:2,
                                        // },
                                    }}
                                    // spaceBetween={30}
                                    navigation={{
                                        clickable: true,
                                    }}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        dynamicBullets: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + '"></span>';
                                        },
                                    }}
                                    modules={[Pagination, Navigation, Autoplay]}
                                    className="mySwiper  "
                                >



                                    <SwiperSlide className='' >
                                        <div className='p-6 text-left bg-white rounded-lg my-10 shadow-lg shadow-slate-400'>

                                            <div className='text-black text-4xl text-left mb-3 '><i class="fa-solid fa-quote-left"></i></div>
                                            <p className='mb-4 text-secondary-800'>Implementing this revolutionary platform has been a game-changer for our institution. It's like a breath of fresh air in managing our daily operations. The intuitive interface coupled with its robust features has significantly enhanced our efficiency.</p>
                                            <hr className='mb-4' />
                                            <div className='flex'>
                                                <div className='mt-4'>
                                                    <span className='border-[#ef3e53] border rounded-full px-4 py-3 secondary-text text-3xl md:text-5xl'><i class="fa-solid fa-user"></i></span>
                                                </div>
                                                <p className='flex flex-col justify-center mx-4 gap-1'> <span className='font-bold text-web-color text-base md:text-[1.2vw]'>Mufti Abu Talha</span><span className='font-thin secondary-text text-xs md:text-sm'>Exeicutive Director</span><span className=' secondary-text font-bold text-xs md:text-sm'>Markaju Talimis Sunnah</span></p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className='' >
                                        <div className='p-6 text-left bg-white rounded-lg my-10 shadow-lg shadow-slate-400'>
                                            <div className='text-black text-4xl text-left mb-3 '><i class="fa-solid fa-quote-left"></i></div>
                                            <p className='mb-4 text-secondary-800'>Integrating this cutting-edge software into our Madrasha has been a game-changer in every sense. It's like having a dedicated partner that understands our unique needs and helps us exceed them effortlessly. </p>
                                            <hr className='mb-4' />
                                            <div className='flex'>
                                                <div className='mt-4'>
                                                    <span className='border-[#ef3e53] border rounded-full px-4 py-3 secondary-text text-3xl md:text-5xl'><i class="fa-solid fa-user"></i></span>
                                                </div>
                                                <p className='flex flex-col justify-center mx-4 gap-1'> <span className='font-bold text-web-color text-base md:text-[1.2vw]'>Mufti Shafiqul Islam</span><span className='font-thin secondary-text text-xs md:text-sm'>Muhtamin</span><span className=' secondary-text font-bold text-xs md:text-sm'>Jamia Karimunnessa</span></p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='' >
                                        <div className='p-6 text-left bg-white rounded-lg my-10 shadow-lg shadow-slate-400'>
                                            <div className='text-black text-4xl text-left mb-3 '><i class="fa-solid fa-quote-left"></i></div>
                                            <p className='mb-4 text-secondary-800'>Choosing this cutting-edge technology for our Madrasha has been nothing short of transformative. It's like having a dedicated team of experts at our fingertips, streamlining every aspect of our educational journey. </p>
                                            <hr className='mb-4' />
                                            <div className='flex'>
                                                <div className='mt-4'>
                                                    <span className='border-[#ef3e53] border rounded-full px-4 py-3 secondary-text text-3xl md:text-5xl'><i class="fa-solid fa-user"></i></span>
                                                </div>
                                                <p className='flex flex-col justify-center mx-4 gap-1'> <span className='font-bold text-web-color text-base md:text-[1.2vw]'>Md Rasel Rana</span><span className='font-thin secondary-text text-xs md:text-sm'>Principal of</span><span className=' secondary-text font-bold text-xs md:text-sm'>The Muslim Academy</span></p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className='' >
                                        <div className='p-6 text-left bg-white rounded-lg my-10 shadow-lg shadow-slate-400'>
                                            <div className='text-black text-4xl text-left mb-3 '><i class="fa-solid fa-quote-left"></i></div>
                                            <p className='mb-4 text-secondary-800'>Transformative software at its finest! Web Shinez Technologies has redefined the way we approach our work. Its versatility and adaptability have empowered our team to tackle challenges with unparalleled efficiency. </p>
                                            <hr className='mb-4' />
                                            <div className='flex'>
                                                <div className='mt-4'>
                                                    <span className='border-[#ef3e53] border rounded-full px-4 py-3 secondary-text text-3xl md:text-5xl'><i class="fa-solid fa-user"></i></span>
                                                </div>
                                                <p className='flex flex-col justify-center mx-4 gap-1'> <span className='font-bold text-web-color text-base md:text-[1.2vw]'>MD. Shariful Islam</span><span className='font-thin secondary-text text-xs md:text-sm'>Proprietor</span><span className=' secondary-text font-bold text-xs md:text-sm'>Mr. Buffet</span></p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className='' >
                                        <div className='p-6 text-left bg-white rounded-lg my-10 shadow-lg shadow-slate-400'>
                                            <div className='text-black text-4xl text-left mb-3 '><i class="fa-solid fa-quote-left"></i></div>
                                            <p className='mb-4 text-secondary-800'>Absolutely indispensable! Web Shinez Technologies has become an integral part of our daily operations. Its intuitive interface and comprehensive features make it a breeze to manage complex tasks efficiently. </p>
                                            <hr className='mb-4' />
                                            <div className='flex'>
                                                <div className='mt-4'>
                                                    <span className='border-[#ef3e53] border rounded-full px-4 py-3 secondary-text text-3xl md:text-5xl'><i class="fa-solid fa-user"></i></span>
                                                </div>
                                                <p className='flex flex-col justify-center mx-4 gap-1'> <span className='font-bold text-web-color text-base md:text-[1.2vw]'>AHM Jahangir</span><span className='font-thin secondary-text text-xs md:text-sm'>Founder & CEO</span><span className=' secondary-text font-bold text-xs md:text-sm'>Agrohop Farms & Products Inc.</span></p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className='' >
                                        <div className='p-6 text-left bg-white rounded-lg my-10 shadow-lg shadow-slate-400'>
                                            <div className='text-black text-4xl text-left mb-3 '><i class="fa-solid fa-quote-left"></i></div>
                                            <p className='mb-4 text-secondary-800'>Exceptional software! As a small business owner, finding efficient solutions is paramount, and Web Shinez Technologies has truly exceeded my expectations. From streamlined user interfaces to robust functionalities, it's been a game-changer for my operations. </p>
                                            <hr className='mb-4' />
                                            <div className='flex'>
                                                <div className='mt-4'>
                                                    <span className='border-[#ef3e53] border rounded-full px-4 py-3 secondary-text text-3xl md:text-5xl'><i class="fa-solid fa-user"></i></span>
                                                </div>
                                                <p className='flex flex-col justify-center mx-4 gap-1'> <span className='font-bold text-web-color text-base md:text-[1.2vw]'>Musab Tahmid</span><span className='font-thin secondary-text text-xs md:text-sm'>Proprietor</span><span className=' secondary-text font-bold text-xs md:text-sm'>Tahmid Air International</span></p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                        </div>


                    </div>
                </section>


                {/* Client Comment section ends  */}
                {/* Why chose us section starts  */}
                <section id="why_Chose_us" className='   bg-white'>
                    <div className='py-[5rem]  md:py-[100px]   px-6  mx-auto h-fit max-w-[1920px] lg:px-16  xl:px-28 2xl:px-[13rem]'>

                        <h1 className='section_header'>Why Chose Us</h1>
                        <div className='details grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4  mt-10 text-center'>
                            <div className='bg-transparent pt-20 ps-14 pb-20 pe-0 border sm:border-r-0 border-[#c1c1c1]'>
                                <div className='flex flex-row'>
                                    <span className='text-4xl text-web-color p-6 bg-[--Body-bg] rounded-lg shadow-[0px 0px 51px 0px rgba(0, 0, 0, 0.09)] flex justify-center items-center text-center w-24 h-20  '><i class="fa-solid fa-hand-holding-dollar"></i></span>
                                    <span className=' ms-2 me-2'>
                                        <span className='text-4xl font-extrabold text-web-color count' data-val="1000">0</span>
                                        <p className='mt-3 font-extrabold text-xs secondary-text'>HAPPY CLIENTS</p>
                                    </span>
                                </div>
                            </div>
                            <div className='bg-transparent pt-20 ps-14 pb-20 pe-0 border min-[1180px]:border-r-0 border-[#c1c1c1]'>
                                <div className='flex flex-row'>
                                    <span className='text-4xl text-web-color p-6 bg-[--Body-bg] rounded-lg shadow-[0px 0px 51px 0px rgba(0, 0, 0, 0.09)] flex justify-center items-center text-center w-24 h-20 '><i class="fa-solid fa-lightbulb"></i></span>
                                    <span className=' ms-2 me-2'>
                                        <span className='text-4xl font-extrabold text-web-color count' data-val="150">0</span>
                                        <p className='mt-3 font-extrabold text-xs secondary-text'>Finished Projects</p>
                                    </span>
                                </div>
                            </div>
                            <div className='bg-transparent pt-20 ps-14 pb-20 pe-0 border sm:border-r-0 border-[#c1c1c1]'>
                                <div className='flex flex-row'>
                                    <span className='text-4xl text-web-color p-6 bg-[--Body-bg] rounded-lg shadow-[0px 0px 51px 0px rgba(0, 0, 0, 0.09)] flex justify-center items-center text-center w-24 h-20 '><i class="fa-solid fa-code-branch"></i></span>
                                    <span className=' ms-2 me-2'>
                                        <span className='text-4xl font-extrabold text-web-color count' data-val="50">0</span>
                                        <p className='mt-3 font-extrabold text-xs secondary-text'>SKILLED EXPERTS</p>
                                    </span>
                                </div>
                            </div>
                            <div className='bg-transparent pt-20 ps-14 pb-20 pe-0 border  border-[#c1c1c1]'>
                                <div className='flex flex-row'>
                                    <span className='text-4xl text-web-color p-6 bg-[--Body-bg] rounded-lg shadow-[0px 0px 51px 0px rgba(0, 0, 0, 0.09)] flex justify-center items-center text-center w-24 h-20 '><i class="fa-solid fa-hand-holding-dollar"></i></span>
                                    <span className=' ms-2 me-2'>
                                        <span className='text-4xl font-extrabold text-web-color count' data-val="25">0</span>
                                        <p className='mt-3 font-extrabold text-xs secondary-text'>Our Product</p>
                                    </span>
                                </div>
                            </div>

                        </div>
                        {/* <div className='mt-10 '>
                            <Swiper

                                slidesPerView={1}
                                // spaceBetween={10}
                                // loop={true}
                                // navigation={true}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                    // dynamicBullets: true,
                                    renderBullet: function (index, className) {
                                        return '<span class="' + className + '"></span>';
                                    },
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 0
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 0
                                    },
                                    1020: {
                                        slidesPerView: 4,
                                        spaceBetween: 0
                                    }
                                }}
                                modules={[Autoplay, Pagination, Autoplay]}
                                className="mySwiper "
                            >
                                <SwiperSlide className='py-10 px-8' >
                                    <div className='mx-auto  grays className='h-[10rem]'cale hover:grayscale-0 '><img src={Client_1} alt="" /></div>
                                </SwiperSlide>
                                <SwiperSlide className='py-10 px-8'>
                                    <div className='mx-auto w-[10rem] md:w-[80%] grayscale hover:grayscale-0'><img src={Client_1} alt="" /></div>
                                </SwiperSlide>
                                <SwiperSlide className='py-10 px-8'>
                                    <div className='mx-auto w-[10rem] md:w-[80%] grayscale hover:grayscale-0'><img src={Client_3} alt="" /></div>
                                </SwiperSlide>
                                <SwiperSlide className='py-10 px-8'>
                                    <div className='mx-auto w-[10rem] md:w-[80%] grayscale hover:grayscale-0'><img src={Client_4} alt="" /></div>
                                </SwiperSlide>
                                <SwiperSlide className='py-10 px-8'>
                                    <div className='mx-auto w-[10rem] md:w-[80%] grayscale hover:grayscale-0'><img src={Client_3} alt="" /></div>
                                </SwiperSlide>
                                <SwiperSlide className='py-10 px-8'>
                                    <div className='mx-auto w-[10rem] md:w-[80%] grayscale hover:grayscale-0'><img src={Client_6} alt="" /></div>
                                </SwiperSlide>
                                <div className="swiper-pagination  grayscale hover:grayscale-0 "></div>
                            </Swiper>
                        </div> */}
                        {/* <div className="details pt-14 ">
                            <div className=" flex flex-col justify-center items-center  ">
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                                    <div className="flex flex-col text-center text-lg lg:text-xl">
                                        <span className=''>We Have</span>
                                        <span className='count text-3xl text-web-color font-bold my-2 lg:mb-4 ' data-val='2000'>0</span>
                                        <span className=' '>Active Software User</span>
                                    </div>
                                    <div className="flex flex-col text-center text-lg lg:text-xl">
                                        <span className=''>We Have</span>
                                        <span className='count text-3xl text-web-color font-bold my-2 lg:mb-4 ' data-val="1000">0</span>
                                        <span className=' '>Active Software User</span>
                                    </div>
                                    <div className="flex flex-col text-center text-lg lg:text-xl">
                                        <span className=''>We Have</span>
                                        <span className='count text-3xl text-web-color font-bold my-2 lg:mb-4 ' data-val='5000'>0</span>
                                        <span className=' '>Active Software User</span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-center text-md mt-6 lg:text-xl lg:w-fit lg:mt-8 lg:mx-auto ">
                                    <div className=' flex flex-col justify-center items-center m-4 lg:m-8'>
                                        <img src={Chose_Card_1} alt="" />
                                        <span className='w-fit text-web-color  mt-2'>Money Back Guarantee</span>
                                    </div>
                                    <div className=' flex flex-col justify-center items-center m-4 lg:m-8'>
                                        <img src={Chose_Card_2} alt="" />
                                        <span className='w-fit text-web-color   mt-2'>100% Client Satisfaction</span>
                                    </div>
                                    <div className=' flex flex-col justify-center items-center m-4 lg:m-8' >
                                        <img src={Chose_Card_3} alt="" />
                                        <span className='w-fit text-web-color   mt-2'>Virus Free Gurantee</span>
                                    </div>
                                    <div className=' flex flex-col justify-center items-center m-4 lg:m-8'>
                                        <img src={Chose_Card_4} alt="" />
                                        <span className='w-fit text-web-color  mt-2'>Customer care & support</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </section>
                {/* Why chose us section ends  */}
                {/* what we do section starts  */}
                <section id='WHAT_WE_DO' className=' text-center bg-[--Body-bg] lg:text-left '>
                    <div className='py-[5rem]  md:py-[100px]  px-6  mx-auto max-w-[1920px] lg:px-16  xl:px-28 2xl:px-[13rem]'>
                        <div className="grid grid-cols-1 lg:grid-cols-2 ">
                            <div className='p-3'>
                                <h1 className='text-left mb-4 section_header text-2xl xs:text-3xl sm:text-4xl'>In anticipation of your achievement, we offer genuinely remarkable IT solutions.</h1>
                                <div className='flex flex-col items-center sm:items-start sm:flex-row mt-8 gap-5'>
                                    <div className='bg-white w-[11rem] h-[13.5rem] ' style={{ background: "linear-gradient(43deg,  rgb(208, 59, 96), rgb(219, 76, 128), rgb(226, 96, 161), rgb(195, 127, 199), rgb(163, 152, 214), rgb(149, 170, 211), rgb(160, 182, 198))" }}>
                                        <div className='px-6 mt-6 mb-8 flex flex-col'>
                                            <span className='text-8xl font-bold text-white'>10</span>
                                            <span className='text-lg text-white'>Years Of Experience</span>
                                        </div>
                                    </div>
                                    <p className='leading-8 '>Our primary aim is to provide digital solutions utilizing cutting-edge information systems and creativity. We've successfully executed projects spanning various industries such as Internet Service Providers, Education, Healthcare, E-commerce, and beyond.</p>
                                </div>
                            </div>
                            <div className="gsap_right_to_left md:mx-auto lg:ps-10 text-left w-full">
                                <div className='counter-bar'>
                                    <div className='skill-item '>
                                        <div className='title'>Business Sollution</div>
                                        <div className='skill-bar'>
                                            <div className='bar-inner'>
                                                <div className='bar progress-line color-1' data-width="91" style={{ width: "0%" }}>
                                                    <span className='skill-percent'>
                                                        <span className='counter'>0</span>
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='skill-item '>
                                        <div className='title'>Data Security</div>
                                        <div className='skill-bar'>
                                            <div className='bar-inner'>
                                                <div className='bar progress-line color-1' data-width="100" style={{ width: "0%" }}>
                                                    <span className='skill-percent'>
                                                        <span className='counter'>0</span>
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='skill-item '>
                                        <div className='title'>Information Technology</div>
                                        <div className='skill-bar'>
                                            <div className='bar-inner'>
                                                <div className='bar progress-line color-1' data-width="95" style={{ width: "0%" }}>
                                                    <span className='skill-percent'>
                                                        <span className='counter'>0</span>
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='skill-item '>
                                        <div className='title'>Technology Consultant</div>
                                        <div className='skill-bar'>
                                            <div className='bar-inner'>
                                                <div className='bar progress-line color-1' data-width="85" style={{ width: "0%" }}>
                                                    <span className='skill-percent'>
                                                        <span className='counter'>0</span>
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Our client section starts */}

                <section id='OUR_CLIENTS' className='bg-white'>
                    <div className='without_bullet_svg py-[5rem]  md:py-[100px]  px-6  mx-auto max-w-[98rem]'>
                        <h1 className='section_header'>Our Clients</h1>
                        <div className=' mt-10 2xl:mt-20'>
                            <Swiper


                                watchSlidesProgress
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                    dynamicBullets: true,
                                    renderBullet: function (index, className) {
                                        return '<span class="' + className + ' "></span>';
                                    },
                                }}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 0
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 0
                                    },
                                    1020: {
                                        slidesPerView: 6,
                                        spaceBetween: 0
                                    }
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper "
                            >
                                <div className={"w-[100%]"}>
                                    <SwiperSlide className='py-10 px-8' >
                                        <div className='mx-auto '><a href='https://www.facebook.com/Mr.Buffetnarayanganj' className='cursor-pointer' target='_blank'> <img className='h-[6rem] md:h-[8rem]' src={Client_1} alt="" /></a></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><a href='https://www.facebook.com/profile.php?id=61557513470042' className='cursor-pointer' target='_blank'> <img className='h-[6rem] md:h-[8rem]' src={Client_2} alt="" /></a></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_3} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_4} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_5} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><a href='https://www.facebook.com/profile.php?id=100076409427759' className='cursor-pointer' target='_blank'> <img className='h-[6rem] md:h-[8rem]' src={Client_6} alt="" /></a></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><a href='https://www.facebook.com/profile.php?id=100063533051343' className='cursor-pointer' target='_blank'> <img className='h-[6rem] md:h-[8rem]' src={Client_7} alt="" /></a></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_8} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_9} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_10} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_11} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_12} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_13} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_13_1} alt="" /></></div>
                                    </SwiperSlide>

                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_15} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_16} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_17} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_18} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_19} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_20} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_21} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_22} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8'>
                                        <div className='mx-auto '><> <img className='h-[6rem] md:h-[8rem]' src={Client_23} alt="" /></></div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8  text-slide' >
                                        <div className='mx-auto w-auto'>Farhad Cycle Store</div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8 text-slide'>
                                        <div className='mx-auto w-auto text-slide'>M/S. PRIME GARMENTS</div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8 text-slide'>
                                        <div className='mx-auto w-auto text-slide'>Biye Bari Restaurant</div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8 text-slide'>
                                        <div className='mx-auto w-auto'>Rimon Telecom</div>
                                    </SwiperSlide>
                                    <SwiperSlide className='py-10 px-8 text-slide'>
                                        <div className='mx-auto w-auto'>M/s. Jamuna Garments</div>
                                    </SwiperSlide>

                                </div>
                                <div className="swiper-pagination pt-6"></div>
                            </Swiper>
                        </div>
                    </div>
                </section>
                {/* Our client section Ends */}



                <section id='Ask_Human' className=' bg-[--Body-bg] '>
                    <div className='py-[5rem] px-6 pt-[100px]  mx-auto max-w-[1920px] lg:px-16  xl:px-28 2xl:px-[13rem]  '>


                        <div className="grid grid-cols-1 md:grid-cols-2 ">
                            <div className="gsap_left_to_right ">
                                <img className='w-[60%] mx-auto' src={Human_In_Desk} alt="" />
                            </div>
                            <div className=' pb-10 lg:pb-0 gsap_right_to_left'>
                                <div className="mt-8 text-center lg:text-left lg:mt-24 lg:ms-10 align-middle items-center ">
                                    <h2 className='text-web-color text-2xl lg:text-3xl font-bold'>Ask Human</h2>
                                    <p className='text-base lg:text-xl mt-4'>Our experts are ready to talk and help you fast and with priority.</p>
                                    <div className="mt-5 flex justify-center lg:justify-normal lg:text-base   ">
                                        <button className='flex-initial vanila_btn_hover_1 border border-[#ef3e53]  text-white font-bold rounded-md   px-3 py-3 lg:px-7 lg:py-3'><a href="tel: +8801913153450">Talk to an expert</a></button>
                                        <button className=' flex-initial vanila_btn_hover_2  font-bold rounded-md  bg-white border border-[#ef3e53] px-3 py-3 lg:px-7 lg:py-3  ms-2 lg:ms-4'><Link to="/contactUs">Get in touch</Link></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


            </div >
        </>
    )
}
