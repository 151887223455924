import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import ContactUs_bg_1 from "../../images/ContactUs_bg_1.jpg"
import Partnership_0 from "../../images/partnership_0.png";
import ContactUs_1 from "../../images/ContactUs_1.png";
import ContactUs_2 from "../../images/ContactUs_2.svg";
import ContactUs_3 from "../../images/ContactUs_3.svg";
import ContactUs_4 from "../../images/ContactUs_4.svg";
// import dotenv from  'dotenv'
// Initialization for ES Users

export default function _19_ContactUs(props) {
    // const errorMessage = useRef({
    //     name: '',
    //     email: '',
    //     phone: '',
    //     subject: '',
    //     message: ''
    // })
    const api = process.env.REACT_APP_BACKEND_API;
    const [errorMessage, setErrorMessage] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    // const error = useRef(false);
    const [error, setError] = useState({
        name: false,
        email: false,
        phone: false,
        subject: false,
        message: false
    });
    useEffect(() => {
        props.navbarStyle(true);
    }, [])
    function validateEmail(email) {
        // Regular expression to validate the email
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        // Test the email against the regex
        if (regex.test(email)) {
            return true; // Email is valid
        } else {
            return false; // Email is invalid
        }
    }
    const formvalidate = async () => {
        error.current = false;
        errorMessage.current = null;
        let name = document.getElementById('name')
        let email = document.getElementById('email')
        let phone = document.getElementById('phone')
        let subject = document.getElementById('subject')
        let message = document.getElementById('message')
        let data = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            subject: subject.value,
            message: message.value
        }
        if (!changeValidation() || name.value == '' || email.value == '' || phone.value == '' || subject.value == '' || message.value == '') {
            setError({
                name: true,
                email: true,
                phone: true,
                subject: true,
                message: true
            })

            setErrorMessage({
                name: "Name must be at least 5 characters.",
                email: "Please enter a valid email.",
                phone: "Please enter a valid phone number.",
                subject: "Subject must be at least 6 character.",
                message: "Message must be at least 11 character."
            })
        }
        else {
            name.value='';
            email.value='';
            phone.value='';
            subject.value ='';
            message.value='';
            const res = await fetch(`${api}/api/send-mail`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Content-Type": "application/json"
                },
            }).then((res) => {
                // if(res.status(200)){
                // }
            })
        }
    }

    const Inputvalue = useRef({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    })


    const changeValidation = () => {
        let nameInput = document.getElementById("name");
        let emailInput = document.getElementById("email");
        let phoneInput = document.getElementById("phone");
        let subjectInput = document.getElementById("subject");
        let messageInput = document.getElementById("message");
        Inputvalue.current = {
            name: nameInput.value,
            email: emailInput.value,
            phone: phoneInput.value,
            subject: subjectInput.value,
            message: messageInput.value
        }
        nameInput.addEventListener("focusout", (ele) => {
            if (Inputvalue.current.name == null || Inputvalue.current.name.length <= 4) {
                setError({
                    name: true
                })
                setErrorMessage({
                    name: "Name must be at least 5 characters."
                })
            }
        })
        emailInput.addEventListener("focusout", () => {
            if (!validateEmail(Inputvalue.current.email)) {
                setError({
                    email: true
                })
                setErrorMessage({
                    email: "Please enter a valid email."
                })
            }
        })

        phoneInput.addEventListener("focusout", () => {
            if (Inputvalue.current.phone == null || Inputvalue.current.phone.length <= 10) {
                setError({
                    phone: true
                })
                setErrorMessage({
                    phone: "Please enter a valid phone number."
                })
            }
        })
        subjectInput.addEventListener("focusout", () => {
            if (Inputvalue.current.subject == null || Inputvalue.current.subject.length <= 5) {
                setError({
                    subject: true
                })
                setErrorMessage({
                    subject: "Subject must be at least 6 character."
                })
            }
        })

        messageInput.addEventListener("focusout", () => {
            if (Inputvalue.current.message == null || Inputvalue.current.message.length <= 10) {
                setError({
                    message: true
                })
                setErrorMessage({
                    message: "Message must be at least 11 character."
                })
            }
        })

        if (Inputvalue.current.name !== null && Inputvalue.current.name.length > 4) {
            setError({
                name: false
            })
            setErrorMessage({
                name: ""
            })
        }
        if (validateEmail(Inputvalue.current.email)) {
            setError({
                email: false
            })
            setErrorMessage({
                email: ""
            })
        }
        if (Inputvalue.current.phone !== null && Inputvalue.current.phone.length > 10) {
            setError({
                phone: false
            })
            setErrorMessage({
                phone: ""
            })
        }
        if (Inputvalue.current.subject !== null && Inputvalue.current.message.length > 5) {
            setError({
                subject: false
            })
            setErrorMessage({
                subject: ""
            })
        }
        if (Inputvalue.current.message !== null && Inputvalue.current.message.length > 10) {
            setError({
                message: false
            })
            setErrorMessage({
                message: ""
            })
        }
        if (!error.name && !error.name && !error.phone && !error.subject && !error.message) {
            return true // valid data
        }
        else {
            return false // invalid data
        }
    }


    return (
        <>
            {/* <div className='relative top-20 md:top-44 mb-10 h-fit md:h-[60rem]'> */}
            <div className='mb-6'>
                <div className='breadcrumbs_bg h-[11rem] 2xl:h-[20rem] flex justify-center items-center'>

                    <nav className="breadcrumbs" style={{ opacity: "1" }}>
                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home
                        </Link>

                        <Link to={'/pages/contactUs'} className="breadcrumbs__item is-active">Contact Us</Link>
                    </nav>

                </div>
                <div className='mx-auto pt-[3rem] md:pt-[100px] px-1 max-w-[1920px] h-auto md:px-16  xl:px-28 2xl:px-[13rem]'>
                    {/* <div id='bradcrumbs' className='hidden mt-6 md:block rounded-md  bg-[--Secondary-Light-color] w-fit '>
                        <ul className='flex text-base text-web-color font-semibold'>
                            <li className='home-icon ps-4 pe-6 py-2 me-4'><Link to={"/"}><i className="fa-solid fa-house"></i> </Link></li>
                            <li className='breadcrumbs-icon  px-6 py-2 me-4'><Link to={"/"}>Company</Link></li>
                            <li className='px-6 py-2 me-4'>Jobs and Career</li>
                        </ul>
                    </div> */}
                    <div className=''>
                        <h1 className='text-2xl text-web-color font-bold text-center lg:text-4xl mt-8 mb-4'>Pre Sales Enquires</h1>
                        <p className='text-base text-center'>Questions regarding our services? We're always happy to help.</p>
                        {/* <div className="grid grid-cols-1 lg:grid-cols-2 mt-8">
                        <div id='partnership' className=' h-full w-full' style={{ backgroundImage: `url(${Partnership_0})` }}>
                            <div className=' bg-white mx-auto w-[80%]  my-20 pt-8 pb-8 ps-8 rounded-2xl'>
                                <div className='flex flex-row items-center gap-4'>
                                    <div>
                                        <h1 className='text-xl lg:text-2xl mb-3 '>Corporation Office</h1>
                                        <hr className='vanila_underline mb-6'></hr>
                                        <p className='text-base mb-4 flex flex-row vanila_text_hover_1'> <span className='text-base me-4 text-cyan-400 mb-4'><i className="fa-solid fa-location-dot"></i></span> <span>Navana La Shateau De Hussain, Flat # B4, Road # 27, Gulshan-01, Dhaka-1212</span> </p>
                                        <h1 className='text-xl lg:text-2xl mb-3'>Head Office</h1>
                                        <hr className='vanila_underline mb-6'></hr>
                                        <p className='text-base mb-4 flex flex-row'> <span className='text-base me-4 text-cyan-400 mb-4'><i className="fa-solid fa-location-dot"></i></span> <span>Navana La Shateau De Hussain, Flat # B4, Road # 27, Gulshan-01, Dhaka-1212</span> </p>
                                        <h1 className='text-xl lg:text-2xl mb-3'>Corporation Office</h1>
                                        <hr className='vanila_underline mb-6'></hr>
                                        <p className='text-base mb-4 flex flex-row '> <span className='text-base me-4 text-cyan-400 mb-4'><i className="fa-solid fa-envelope"></i></span> <span>Admin@gmail.com <br />Admin2@gmail.com</span> </p>
                                        <p className='text-base mb-4 flex flex-row '> <span className='text-base me-4 text-cyan-400 mb-4'><i className="fa-solid fa-phone"></i></span> <span>Admin@gmail.com <br />Admin2@gmail.com</span> </p>


                                    </div>
                                    <div>
                                        <div className='mb-4  text-center px-6  py-4 text-lg bg-blue-500 text-white'><i className="fa-brands fa-facebook-f"></i></div>
                                        <div className='mb-4 text-center py-4 px-6  text-lg bg-blue-700 text-white'><i className="fa-brands fa-linkedin-in"></i></div>
                                        <div className='mb-4 text-center py-4 px-6  text-lg bg-red-600 text-white'><i className="fa-brands fa-youtube"></i></div>
                                        <div className='mb-4 text-center py-4 px-6  text-lg bg-blue-400 text-white'><i className="fa-brands fa-twitter"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <form className='mt-8  w-2/3 mx-auto lg:mx-8 ' action="">

                                <label className="block mb-4">
                                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                                        Full Name
                                    </span>
                                    <input type="text" name="text" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Type Full Name" />
                                </label>
                                <label className="block mb-4">
                                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                                        Email Address
                                    </span>
                                    <input type="email" name="email" className="mt-1 px-3 py-2 peer bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Type Email Address" />
                                    <p className="mt-2 invisible peer-invalid:visible text-pink-600 text-sm">
                                        Please provide a valid email address.
                                    </p>
                                </label>
                                <label className="block mb-4">
                                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                                        Your Subject
                                    </span>
                                    <input type="text" name="text" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Type Full Name" />
                                </label>
                                <label className="block mb-4">
                                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                                        Your Quires
                                    </span>
                                    <textarea name="" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" id="" cols="10" rows="10"></textarea>
                                </label>

                                <button type='submit' className='mt-6 md:my-8 px-6 py-2 text-xl  vanila_btn_hover_1 '>Get a Qutos</button>
                            </form>
                        </div>
                    </div > */}

                        <div className='mt-8 grid grid-cols-1 md:grid-cols-2 '>
                            <div className='px-8 py-10  flex  justify-start items-center'>
                                <div className='secondary-bg rounded-lg h-full px-4 py-8  flex flex-col justify-between items-center gap-10 '>
                                    <div className='px-4 py-3 text-web-color  bg-white rounded-full'><span className=' text-2xl'><i className="fa-solid fa-envelope"></i></span></div>
                                    <div className='px-4 py-3 text-web-color  bg-white rounded-full'><span className=' text-2xl'><i className="fa-solid fa-phone-volume"></i></span></div>
                                    <div className='px-4 py-3 text-web-color  bg-white rounded-full'><span className=' text-2xl'><i className="fa-solid fa-headset"></i></span></div>
                                    <div className='px-[1.2rem] py-[0.8rem] text-web-color  bg-white rounded-full'><span className=' text-2xl'><i className="fa-solid fa-location-dot"></i></span></div>
                                    <div className='px-[1.2rem] py-[0.8rem]  text-web-color  bg-white rounded-full'><span className=' text-2xl'><i className="fa-solid fa-location-dot"></i></span></div>
                                    <div className='px-4 py-3 text-web-color  bg-white rounded-full'><span className=' text-2xl'><i className="fa-solid fa-briefcase"></i></span></div>
                                </div>
                                <div className=' h-full py-5 mx-4 flex flex-col  justify-start items-start '>
                                    <span className='mt-5 '><a href="mailto: info@webshinez.com" target='_blank'>info@webshinez.com</a><br /> <a href="mailto: support@webshinez.com" target='_blank'>support@webshinez.com</a></span>
                                    <span className='mt-14 '><a href="tel: +8801913153450 " target='_blank'>+88 01913153450</a><br /><a href="tel: +8801893772866" target='_blank'>+88 01893772866</a></span>
                                    <span className='mt-12 '><div className='font-bold'>Support 24/7 :</div><a href="tel: +8809639153450" target='_blank'> +88 09639153450</a></span>
                                    <span className='mt-12 '><a href="https://maps.app.goo.gl/4orq9JeBbggFhc7c7" target='_blank'><div className='font-bold'>Head Office:</div> Holding: 1254, Miapara, <br /> Jamalpur Sadar, Jamalpur-2000 </a></span>

                                    <span className='mt-9'><a href="https://maps.app.goo.gl/t5zX6voJCGxKBDDz5" target='_blank'><div className='font-bold'>Corporate Office:</div>  1 No. Alam Khan Lane,  <br />BB Road, Narayangonj -1400</a></span>

                                    <span className=' mt-11'>10:00 AM to 8:00 PM <br /> Saturday  to Thursday</span>

                                </div>
                            </div>
                            <form onSubmit={(e) => { e.preventDefault(); changeValidation(); formvalidate(); }}>
                                {/* <!-- component --> */}
                                {/* <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12"> */}
                                <div>
                                    <div className="relative py-3  ">
                                        {/* <div
                                        className="absolute inset-0 bg-gradient-to-r from-blue-300 primary-bg shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                                    </div> */}
                                        <div className="   ">
                                            <div className="px-8">
                                                <div>
                                                    <h1 className="text-2xl text-web-color font-semibold">Get in touch</h1>
                                                </div>
                                                <div className=" ">
                                                    <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                                        <div className="relative mb-5">
                                                            <input autocomplete="off" onChange={() => { changeValidation() }} id="name" name="Name" type="text" className="peer placeholder-transparent bg-transparent  h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Enter your name" />
                                                            <label htmlFor="Name" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-sm " >Name <span className=''>*</span></label>
                                                            <span className='text-sm text-web-color'>{error.name && errorMessage.name}</span>
                                                        </div>
                                                        <div className="relative mb-5" style={{ marginTop: "2rem" }}>
                                                            <input autocomplete="off" onChange={() => { changeValidation() }} id="email" name="email" type="email" className="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" />
                                                            <label htmlFor="email" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-sm">Email Address <span className=''>*</span></label>
                                                            <span className='text-sm text-web-color'>{error.email && errorMessage.email}</span>
                                                        </div>
                                                        <div className="relative mb-5" style={{ marginTop: "2rem" }}>
                                                            <input autocomplete="off" onChange={() => { changeValidation() }} id="phone" name="phone" type="number" className="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Phone" />
                                                            <label htmlFor="Phone" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-sm"> Phone <span className=''>*</span></label>
                                                            <span className='text-sm text-web-color'>{error.phone && errorMessage.phone}</span>
                                                        </div>

                                                        <div className="relative mb-10" style={{ marginTop: "2rem" }}>
                                                            <input autocomplete="off" onChange={() => { changeValidation() }} id="subject" name="Subject" type="text" className="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Subject" />
                                                            <label htmlFor="Subject" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-sm">Subject <span className=''>*</span></label>
                                                            <span className='text-sm text-web-color'>{error.subject && errorMessage.subject}</span>
                                                        </div>
                                                        <div className="relative mb-5 mt-10" style={{ marginTop: "2rem" }} data-te-input-wrapper-init>
                                                            <textarea onChange={() => { changeValidation() }}
                                                                className="peer bg-transparent placeholder:text-md placeholder:text-gray-600 placeholder:font-thin w-full border-b-2 border-gray-300 text-gray-900 active:outline-none hover:outline-none focus-within:outline-none focus:outline-none focus:borer-rose-600 focus:placeholder:opacity-0 data-[te-input-state-active]:placeholder:opacity-0 motion-reduce:transition-none dark:text-neutral-200 "
                                                                id="message"
                                                                rows="6"

                                                                placeholder="Your Message *" ></textarea>
                                                            <span className='text-sm text-web-color'>{error.message && errorMessage.message}</span>
                                                            {/* <label
                                                                htmlFor="exampleFormControlTextarea1"
                                                                className="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                                            >Example textarea</label> */}
                                                        </div>

                                                        <div className="text-right">
                                                            <button className={!error.name && !error.email && !error.phone && !error.subject && !error.message ? " vanila_btn_hover_2 text-white rounded-lg px-5 py-2" : "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"}>Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >


                </div>
                {/* <div className='mx-auto py-[3rem] md:py-0 px-1 max-w-[1920px] h-auto md:px-16  xl:px-28 2xl:px-[13rem] hidden md:block'>
                    <div className="grid grid-cols-1  sm:grid-cols-3 ">
                        <div className='bg-blue-500 text-center py-8 lg:text-xl text-white'>
                            <span className='mx-4 text-2xl bg-blue-700 rounded-full p-2'><i className="fa-brands fa-telegram"></i></span><span>Join Telegram</span>
                        </div>
                        <div className='bg-cyan-400 text-center py-8 lg:text-xl text-white'>
                            <span className='mx-4 text-2xl bg-white text-cyan-400 rounded-full px-2 py-[6px]'><i className="fa-brands fa-skype"></i></span><span>Join Telegram</span>
                        </div>
                        <div className='bg-blue-500 text-center py-8 lg:text-xl text-white'>
                            <span className='mx-4 text-2xl bg-blue-700 rounded-full p-2'><i className="fa-brands fa-telegram"></i></span><span>Join Telegram</span>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )

}

