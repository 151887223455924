import React, { Component } from 'react';
import { Outlet } from 'react-router-dom'
import Footer from './component/Footer'
import Navbar from './component/Navbar';


export class Body extends Component {
  constructor(){
    super()
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      let scrolling_top = document.getElementById('scrolling-top');
      if (winScroll > 600) {
        scrolling_top.classList.remove('hidden');
        scrolling_top.classList.add('block');
      }
      else {
        scrolling_top.classList.remove('block');
        scrolling_top.classList.add('hidden');
      }
    })
  }

  scrolling = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  render() {
    return (
      <>
        <Navbar navbarStyle={this.props.navbarStyle} />
        <div id='body'>
          <Outlet />
          <span id='scrolling-top' onClick={this.scrolling} className='vanila_btn_hover_1 px-4 py-3 rounded-md hidden  fixed bottom-14 right-10 z-50 text-lg delay-0  text-white cursor-pointer'><i class="fa-solid fa-chevron-up" ></i></span>
          <Footer />
        </div>
      </>
    )
  }
}

export default Body