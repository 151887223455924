import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import IspDigitals_1 from ".././../images/ispDigital_1.png";
import IspDigitals_2 from ".././../images/ispDigital_2.png";
import IspDigitals_3 from ".././../images/ispDigital_3.png";
import IspDigitals_4 from ".././../images/ispDigital_4.svg";
import IspDigitals_5 from ".././../images/ispDigital_5.png";
import About_us_15 from "../../images/5155707_2717001.png";
import Techonology_we_use_new_1 from "../../images/techonology_we_use_new_1.webp";
import Techonology_we_use_new_2 from "../../images/techonology_we_use_new_2.webp";
import Techonology_we_use_new_3 from "../../images/techonology_we_use_new_3.webp";
import Techonology_we_use_new_4 from "../../images/techonology_we_use_new_4.webp";
import Techonology_we_use_new_5 from "../../images/techonology_we_use_new_5.webp";
import Techonology_we_use_new_6 from "../../images/techonology_we_use_new_6.webp";
import Techonology_we_use_new_7 from "../../images/techonology_we_use_new_7.webp";
import Techonology_we_use_new_8 from "../../images/techonology_we_use_new_8.webp";
import Techonology_we_use_new_9 from "../../images/reactjs.png";
import Techonology_we_use_new_10 from "../../images/laravel.png";
import Techonology_we_use_new_11 from "../../images/codeigniter.png";
import Multilayer_Security from "../../images/Multilayer- Security.png";
import Analysis_Reporting from "../../images/analy_image.jpg";
import jQuery from 'jquery';
import $ from "jquery";
export default function _01_IspDgital(props) {

    useEffect(() => {
        props.navbarStyle(true);

        return () => {

        }
    }, [])


    // freequiently ask question section starts
    const hiddenClicker = (element) => {
        let parent = element.target.closest('.Frequently_ask_box_main')
        if (parent !== null) {
            let icon = parent.getElementsByClassName('fa-solid')[0]
            let boxs = parent.getElementsByClassName('Frequently_ask_box')[0]
            let height;
            if (window.screen.width >500) {
                height = parent.getAttribute("data-height");
            }
            if(window.screen.width <=500){
                height = parent.getAttribute("data-mob-height");
            }
            
            console.log(height);
            let main_box = document.getElementsByClassName('Frequently_ask_box')
            if (!boxs.classList.contains('Frequently_ask_box_vissible')) {
                for (let index = 0; index < main_box.length; index++) {
                    const element = main_box[index];
                    if (element.classList.contains('Frequently_ask_box_vissible')) {
                        element.classList.remove('Frequently_ask_box_vissible');
                        element.style.height = "0rem ";
                        let sub_parent = element.closest('.Frequently_ask_box_main')
                        let icon2 = sub_parent.getElementsByClassName('fa-solid')[0]
                        icon2.classList.remove('fa-circle-xmark')
                        icon2.classList.add('fa-chevron-down')
                    }
                }
                boxs.classList.add("Frequently_ask_box_vissible")
                boxs.style.height = `${height}`;
                icon.classList.remove('fa-chevron-down')
                icon.classList.add('fa-circle-xmark')
            }
            else {
                for (let index = 0; index < main_box.length; index++) {
                    const element = main_box[index];
                    if (element.classList.contains('Frequently_ask_box_vissible')) {
                        element.classList.remove('Frequently_ask_box_vissible')
                        element.style.height = "0rem";
                        let sub_parent = element.closest('.Frequently_ask_box_main')
                        let icon2 = sub_parent.getElementsByClassName('fa-solid')[0]
                        icon2.classList.remove('fa-circle-xmark')
                        icon2.classList.add('fa-chevron-down')
                    }
                }
                boxs.classList.remove("Frequently_ask_box_vissible")
                boxs.style.height = "0rem";
                icon.classList.add('fa-chevron-down')
                icon.classList.remove('fa-circle-xmark')
            }
            
        }
    }
    // freequiently ask question section ends
    
    return (
        <>
            <div id='service_details' className='text-center lg:text-left ' style={{ backgroundColor: "#EDF0F8" }}>
                <div className='breadcrumbs_bg h-[11rem] 2xl:h-[20rem] flex justify-center items-center' style={{background:"url(https://source.unsplash.com/random?erp) "}}>

                    <nav class="breadcrumbs" style={{ opacity: "1" }}>
                        <Link to={'/'} class="breadcrumbs__item"><i class="text-black fa-solid fa-house"></i> Home</Link>

                        <Link to={'/bizzSelf'} class="breadcrumbs__item is-active">BizzSelf</Link>
                    </nav>

                </div>
                <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                    <h1 className='section_header  ' >BizzSelf </h1>
                    <h4 className='text-2xl text-web-color font-bold text-center mt-2 mb-8 '> Business Management Solution Software</h4>
                    <div className='text-justify text-xl leading-8 text-secondary-700 '>
                        <div>BezzSelf is an ERP software We are committed to providing organizations in an array of industries with innovative technologies. Focused on modern technology while committed to excellence, we provide customized software solutions that enhance growth, increase efficiency, and streamline operations to assist our clients achieve their goals.

                            At BezzSelf, we pride ourselves on being pioneers in ERP software solutions. Our dedication lies in empowering organizations across diverse industries with state-of-the-art technologies. Driven by a passion for innovation and excellence, we specialize in tailoring software solutions to meet the unique needs of each client. With a keen focus on modern technology, we strive to deliver products that not only foster growth but also enhance efficiency and streamline operations. Our ultimate aim is to partner with our clients on their journey to success, providing robust solutions that enable them to achieve their goals with confidence.
                        </div>
                        {/* <div>Our futuristic solutions help empowering the financial institutions to be more competitive and develop lasting customer experience. By embracing the latest technologies, we build customized banking solutions and dedicated mobile apps to leverage your customer reach-ability and enhance flexibility.</div> */}
                    </div>
                </div>

                <div className='px-4 md:px-16 py-16 bg-[#EEF0F8] lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <div className='align-middle text-left flex justify-center lg:justify-start items-center'>
                            <div>
                                <h3 className='text-2xl  lg:text-4xl font-semibold text-center lg:text-left'>BizzSelf Will Provide You</h3>
                                <hr className='h-[0.2rem] w-[6rem] bg-[--Primary-color] mt-4 mx-auto lg:mx-0' />
                            </div>
                        </div>
                        <div className='mt-8 mx-auto lg:mx-0 !text-left'>
                            <div  data-height="10rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex '   onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right fa-circle-xmark"></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Multiple Business Management
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box Frequently_ask_box_vissible'>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>➤ Set up and manage multiple businesses without restrictions.
                                        <br />
                                        <br />
                                        ➤ Maintain separate inventory and accounting data for each business.
                                    </p>
                                </div>
                            </div>
                            <div data-height="10rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' data-height="10" onClick={(e) => { hiddenClicker(e);  }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Location Management
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Create and manage multiple locations (storefronts/warehouses) together.
                                        <br /><br />
                                        ➤ Track stocks, purchases, and sales independently for each location.
                                        <br /><br/>
                                        ➤ Customize invoice layout and scheme for individual locations.
                                        <br /><br />
                                    </p>
                                </div>
                            </div>
                            <div data-height="11rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>User & Role Management
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Robust system for managing users and roles.                                        <br /><br />

                                        ➤ Predefined roles (Admin & Cashier) are provided with the option to create custom roles.
                                        <br /><br />
                                        ➤ Unlimited user creation with diverse role assignments.

                                    </p>
                                </div>
                            </div>
                            <div data-height="10rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>
                                        Contacts Management
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Categorize contacts as customers, suppliers, or both.<br /><br />
                                        ➤ Access detailed transaction histories for contacts.<br /><br />
                                        ➤ Monitor credit/debit balances and set payment terms with alerts.


                                    </p>
                                </div>
                            </div>
                            <div data-height="12rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>
                                        Product Management
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Manage both single and variable products efficiently.<br /><br />
                                        ➤ Classify products by brands, categories, and sub-categories.<br /><br />
                                        ➤ Automatic SKU generation and stock alerts for streamlined inventory control.<br /><br />
                                        ➤ Smart pricing calculations based on purchase price and profit margin.



                                    </p>
                                </div>
                            </div>
                            <div data-height="10rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>
                                        Purchases Management
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Easily add and manage purchases across different locations.<br /><br />
                                        ➤ Track paid and due purchases with timely notifications.<br /><br />
                                        ➤ Apply discounts and taxes as needed.
                                    </p>
                                </div>
                            </div>
                            <div data-height="20rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>
                                        Sales Management
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Simplified interface for selling products.<br /><br />
                                        ➤ Automatic addition of walk-in customers.<br /><br />
                                        ➤ Add new customers directly from the Point of Sale (POS) screen<br /><br />
                                        ➤ Customizable invoice layout and scheme.<br /><br />
                                        ➤ Expense Management.<br /><br />
                                        ➤ Easily record and categorize business expenses.<br /><br />
                                        ➤ Analyze expenses by category and location with detailed reports.<br /><br />

                                    </p>
                                </div>
                            </div>
                            <div data-height="10rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>
                                        Reporting Capabilities
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Access comprehensive reports including purchase, sale, tax, contact, stock, and expense reports.<br /><br />
                                        ➤ Drill down into product trends and sales representative performances.

                                    </p>
                                </div>
                            </div>
                            <div data-height="15rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>
                                        Additional Features
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        ➤ Customize currency, timezone, and financial year settings.<br /><br />
                                        ➤ Translation-ready for broader accessibility.<br /><br />
                                        ➤ Barcode settings management.<br /><br />
                                        ➤ Detailed documentation for guidance.<br /><br />
                                        ➤ Stock adjustment and express checkout options.


                                    </p>
                                </div>
                            </div>
                            <div data-height="69rem" data-mob-height="100%" className=' Frequently_ask_box_main box-border rounded-md shadow-xl bg-[#e7e7e7] w-full   px-4 py-2 my-5'  >
                                <div className='flex ' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <span className=' text-web-color font-bold text-2xl me-6'><i className="fa-solid fa-chevron-right "></i></span>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>
                                        Chart OF Accounts
                                    </h5>

                                </div>
                                <div className=' Frequently_ask_box '  >
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>
                                        <span className='font-bold text-base'>Organized Structure: </span>

                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Accounts organized in a sequence.<br />
                                            ➤ Categories for assets, liabilities, equity, revenue, and expenses.<br />
                                        </p>
                                        <span className='font-bold text-base'>Flexibility: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Customizable to suit the specific needs of the organization.<br />
                                            ➤ Ability to add, edit, or remove accounts as required.<br />
                                        </p>
                                        <span className='font-bold text-base'>Standardization: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Respect for accounting rules and guidelines.<br />
                                            ➤ Consistent naming conventions for clarity and consistency.<br />
                                        </p>
                                        <span className='font-bold text-base'>Comprehensiveness: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Coverage of all financial transactions and activities.<br />
                                            ➤ Inclusion of both primary and auxiliary accounts.<br />
                                        </p>
                                        <span className='font-bold text-base'>Integration: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Seamless integration with accounting software or ERP systems.<br />
                                            ➤ Compatibility with other financial management tools.<br />
                                        </p>
                                        <span className='font-bold text-base'>Accessibility: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Easy access for authorized users.<br />
                                            ➤ Secure login and permission-based access control.<br />
                                        </p>
                                        <span className='font-bold text-base'>Reporting: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Generation of financial reports such as balance sheets, income statements, and cash flow statements.<br />
                                            ➤ Ability to customize reports based on specific requirements.<br />
                                        </p>
                                        <span className='font-bold text-base'>                                        Analysis: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Facilitation of financial analysis and decision-making.<br />
                                            ➤ Tools for budgeting, forecasting, and variance analysis.<br />
                                        </p>
                                        <span className='font-bold text-base'>Audit Trail: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Maintenance of a detailed audit trail for accountability and compliance purposes.<br />
                                            ➤ Tracking of changes to accounts over time.<br />
                                        </p>
                                        <span className='font-bold text-base'>Scalability: </span>
                                        <p className='ms-4 mt-3 mb-6'>
                                            ➤ Ability to accommodate growth and expansion of the organization.<br />
                                            ➤ Support for multiple currencies and subsidiaries if applicable.<br />
                                        </p>


                                    </p>
                                </div>
                            </div>

                            {/* <div className='service-item flex  items-center text-2xl lg:text-3xl mb-8'>
                                <div><span className='text-web-color  '><i class="fa-solid fa-angle-right"></i></span> <span className='ms-3 text-secondary-700'>Cloud Services for Banking</span></div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2 '>
                        <div className='text-left mt-10 lg:mt-0'>
                            <h1 className='text-2xl lg:text-4xl text-web-color '>Detailed  Reporting &  Financial Analysis</h1>
                            <p className='my-8 text-lg' >Bizzself ERP extensive reporting feature is one of the most significant tools for planning, monitoring, analyzing, and projecting the state of your company.
</p>
                            <div className='text-[0.9rem] mx-auto lg:mx-0'>
                                <div className='flex mb-6'>
                                    <div className='text-web-color px-[0.57rem] me-4 py-1 h-fit rounded-full border border-[--Primary-color] '><i className='fa fa-check'></i></div> <div>Dashboard monitoring</div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color px-[0.57rem] me-4 py-1 h-fit rounded-full border border-[--Primary-color] '><i className='fa fa-check'></i></div> <div>Client and Supplier-wise reports</div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color px-[0.57rem] me-4 py-1 h-fit rounded-full border border-[--Primary-color] '><i className='fa fa-check'></i></div> <div>Client and Supplier Chacq Monitoring reports</div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color px-[0.57rem] me-4 py-1 h-fit rounded-full border border-[--Primary-color] '><i className='fa fa-check'></i></div> <div>Payments & Received history reports</div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color px-[0.57rem] me-4 py-1 h-fit rounded-full border border-[--Primary-color] '><i className='fa fa-check'></i></div> <div>Client and Product-wise profit-loss report</div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color px-[0.57rem] me-4 py-1 h-fit rounded-full border border-[--Primary-color] '><i className='fa fa-check'></i></div> <div>Manual & automated barcode generation</div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color px-[0.57rem] me-4 py-1 h-fit rounded-full border border-[--Primary-color] '><i className='fa fa-check'></i></div> <div>Manual & automated invoice generation
</div>
                                </div>
                            </div>
                        </div>
                        <div className='my-auto mx-auto lg:ms-auto lg:me-0'>
                            <img src={Analysis_Reporting} />
                        </div>

                    </div>
                </div>

                <div className='px-4 md:px-16 py-16 bg-[#EEF0F8] lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <div className='my-auto mx-auto lg:mx-0'>
                            <img src={Multilayer_Security} />
                        </div>
                        <div className='text-left mt-10 lg:mt-0'>
                            <h1 className='text-2xl lg:text-4xl text-web-color '>Multilayer Security</h1>
                            <p className='my-8 text-lg' >Most of the business provider ignores this feature but using Webshinez It Solution Company, you can expect maximum security against unauthorized login or hacking attempts.
</p>
                            <div className='text-[0.9rem] mx-auto lg:mx-0 text-justify'>
                                <div className='flex mb-6 '>
                                    <div className='text-web-color flex justify-center items-center  me-4 py-3 px-4 w-fit h-fit rounded-full border border-[--Primary-color] '><i class="fa-solid fa-1"></i></div> <div>This feature ensures that users only have access to the specific functions and data necessary for their role within the organization, reducing the risk of unauthorized access. </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center px-4 me-4 py-3 w-fit h-fit rounded-full border border-[--Primary-color] '><i className='fa-solid fa-2'></i></div> <div>Encrypting data both in transit and at rest helps protect sensitive information from being intercepted or accessed by unauthorized parties. </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center  me-4 h-fit w-fit py-3  px-4  rounded-full border border-[--Primary-color] '><i className='fa-solid fa-3'></i></div> <div>MFA adds an extra layer of security by requiring users to provide multiple forms of verification, such as a password and a one-time code sent to their phone, before gaining access to the system. </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center  me-4 h-fit w-fit py-3  px-4 rounded-full border border-[--Primary-color] '><i className='fa-solid fa-4'></i></div> <div>Conducting regular security audits and penetration tests helps identify and address vulnerabilities in the software before they can be exploited by malicious actors. </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center me-4 h-fit w-fit py-3  px-4  rounded-full border border-[--Primary-color] '><i className='fa-solid fa-5'></i></div> <div>Recording and monitoring user activity within the software can help detect suspicious behavior and unauthorized access attempts in real-time. </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center  me-4 h-fit w-fit py-3  px-4  rounded-full border border-[--Primary-color] '><i className='fa-solid fa-6'></i></div> <div>Keeping the software up-to-date with the latest security patches and fixes helps protect against known vulnerabilities and exploits.  </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center  me-4 h-fit w-fit py-3  px-4  rounded-full border border-[--Primary-color] '><i className='fa-solid fa-7'></i></div> <div>Implementing a firewall helps filter incoming and outgoing network traffic, preventing unauthorized access to the system and protecting against various cyber threats.  </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center  me-4 h-fit w-fit py-3  px-4  rounded-full border border-[--Primary-color] '><i className='fa-solid fa-8'></i></div> <div>Regularly backing up data and having a reliable recovery plan in place ensures that critical information can be restored in the event of a security breach or data loss incident. </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center  me-4 h-fit w-fit py-3  px-4 rounded-full border border-[--Primary-color] '><i className='fa-solid fa-9'></i></div> <div>If the software integrates with other systems or services via APIs, ensuring that these APIs are secure and properly authenticated helps prevent unauthorized access to sensitive data.  </div>
                                </div>
                                <div className='flex mb-6'>
                                    <div className='text-web-color flex justify-center items-center  me-4  h-fit w-fit p-3 rounded-full border border-[--Primary-color] '><i className='fa-solid fa-1'></i><i className='fa-solid fa-0'></i></div> <div>Educating users about best practices for password security, phishing awareness, and other security-related topics can help prevent common security threats such as password theft and social engineering attacks.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                    <h1 className=' text-3xl lg:text-5xl text-center font-bold ' >
                        Technologies we use
                    </h1>
                    <hr className='h-[0.2rem] w-[10rem] bg-[--Primary-color] mt-4 mb-8 md:mb-16 mx-auto ' />
                    <div>
                        <Swiper

                            slidesPerView={1}
                            // spaceBetween={10}
                            loop={true}
                            // navigation={true}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                                // dynamicBullets: true,
                                renderBullet: function (index, className) {
                                    return '<span class="' + className + '"></span>';
                                },
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 3,
                                    spaceBetween: 0
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 0
                                },
                                1020: {
                                    slidesPerView: 5,
                                    spaceBetween: 0
                                }
                            }}
                            modules={[Autoplay, Pagination, Autoplay]}
                            className="mySwiper "
                        >
                            <SwiperSlide className='' >
                                <div className='mx-auto w-[10rem] md:w-[80%]  '><img src={Techonology_we_use_new_1} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_2} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_3} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_4} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_5} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_6} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_7} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_8} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_9} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_10} alt="" /></div>
                            </SwiperSlide>
                            <SwiperSlide className=''>
                                <div className='mx-auto w-[10rem] md:w-[80%] '><img src={Techonology_we_use_new_11} alt="" /></div>
                            </SwiperSlide>
                           
                            <div className="swiper-pagination   "></div>
                        </Swiper>
                    </div>
                </div>



                {/* <div className='px-4 md:px-16 py-16 bg-white lg:px-32 lg:pt-20 lg:pb-10 2xl:px-[27%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <div className='mx-auto'>
                            <img src={IspDigitals_2} alt="" className='rounded-lg' />
                        </div>
                        <div className='mt-10 lg:ms-6' >
                            <h3 className='text-web-color text-xl md:text-3xl lg:text-4xl font-bold'>Discover the potency of our solutions.</h3>
                            <p className='mt-5 text-base'>Rely on our proficiency to transform your business environment, unleashing its maximum potential and driving it towards enduring prosperity. Encounter the strength of collaboration with our extensive range of software solutions.</p>
                            <button className='mt-5 rounded-sm px-6 py-2  text-base vanila_btn_hover_1'>Know More</button>
                        </div>

                    </div>
                </div>
                <div className='px-4 md:px-16 pb-16 bg-white lg:px-32 lg:pb-20 lg:pt-10 2xl:px-[27%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <div className='mt-4 mb-8 lg:me-6' >
                            <h3 className='text-web-color text-xl md:text-3xl lg:text-4xl font-bold'>Explore the synergy-driven capabilities of our comprehensive software solutions.</h3>
                            <p className='mt-5 text-base'>Empowering Education, Simplifying Administration: Your School's Success Starts Here. With our intuitive management software, streamline operations, enhance communication, and elevate learning experiences. From attendance tracking to Result management, we provide comprehensive solutions tailored to your institution's needs.</p>
                            <button className='mt-5 rounded-sm px-6 py-2  text-base vanila_btn_hover_2'>Know More</button>
                        </div>
                        <div className='mx-auto'>
                            <img className='rounded-lg' src={IspDigitals_3} alt="" />
                        </div>
                    </div>
                </div>

                <div id='knowMOre_about_ispDigital' className='px-4 md:px-16 py-20 lg:px-32 lg:py-20 2xl:px-[27%]' style={{ backgroundColor: "#EDF0F8" }}>
                    <h1 className='text-center text-web-color font-semibold text-xl md:text-3xl lg:text-4xl'>Know More About Isp Digital</h1>
                    <div className='mt-10 grid grid-cols-1 lg:grid-cols-2 gap-10'>
                        <div className='mx-auto'>
                            <img className=' rounded-lg w-[78rem] mt-10' src={About_us_15} alt="" />
                        </div>
                        <div className='mt-6 lg:ms-6 '>
                            <div className=' Frequently_ask_box_main box-border rounded-md drop-shadow-md w-full  bg-white px-4 py-2 my-5'  >
                                <div className='flex justify-between' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Our Comprehensive Software Solutions.
                                    </h5>
                                    <span className=' text-web-color font-bold text-2xl'><i className="fa-solid fa-chevron-down"></i></span>
                                </div>
                                <div className=' Frequently_ask_box Frequently_ask_box_vissible'>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>Trust in our expertise to revolutionize your business landscape, unlocking its full potential and propelling it towards sustainable prosperity. Experience the power of synergy with our comprehensive Software solutions</p>
                                </div>
                            </div>
                            <div className=' Frequently_ask_box_main box-border rounded-md drop-shadow-md w-full  bg-white px-4 py-2 my-5'  >
                                <div className='flex justify-between' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Our School Solution</h5>
                                    <span className=' text-web-color font-bold text-2xl'><i className="fa-solid fa-chevron-down"></i></span>
                                </div>
                                <div className=' Frequently_ask_box'>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>Empowering Education, Simplifying Administration: Your School's Success Starts Here. With our intuitive management software, streamline operations, enhance communication, and elevate learning experiences. From attendance tracking to Result management, we provide comprehensive solutions tailored to your institution's needs.</p>
                                </div>
                            </div>
                            <div className=' Frequently_ask_box_main box-border rounded-md drop-shadow-md w-full  bg-white px-4 py-2 my-5'  >
                                <div className='flex justify-between' onClick={(e) => { hiddenClicker(e) }} style={{ cursor: 'pointer' }}>
                                    <h5 className='text-base md:text-xl text-web-color font-semibold mb-2'>Our Madrasha Solution</h5>
                                    <span className=' text-web-color font-bold text-2xl'><i className="fa-solid fa-chevron-down"></i></span>
                                </div>
                                <div className=' Frequently_ask_box'>
                                    <hr />
                                    <p className='ps-1 pt-6 pb-4'>Embrace efficiency, embrace innovation, and watch your Madrasha thrive with our cutting-edge technology. Join the countless educators, administrators, and parents who trust our platform to optimize every aspect of the educational journey. Experience the ease of managing your Madrasha with precision and ease. Together, let's shape a brighter future for education, one seamless solution at a time.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='px-4 md:px-16 py-14 bg-white lg:px-32 lg:pb-20 lg:pt-10 2xl:px-[27%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <div className='mt-6 mb-6 lg:me-6' >
                            <h3 className='text-web-color text-xl md:text-3xl lg:text-4xl font-bold'>Together, let's shape a brighter future for education</h3>
                            <p className='mt-5 text-base'>Embrace efficiency, embrace innovation, and watch your Madrasha thrive with our cutting-edge technology. Join the countless educators, administrators, and parents who trust our platform to optimize every aspect of the educational journey. Experience the ease of managing your Madrasha with precision and ease. Together, let's shape a brighter future for education, one seamless solution at a time.</p>
                            <button className='mt-5 rounded-sm px-6 py-2  text-base vanila_btn_hover_1'>Know More</button>
                        </div>
                        <div className='mx-auto'>
                            <img src={IspDigitals_5} alt="" className='rounded-lg' />
                        </div>
                    </div>
                </div> */}


            </div>
        </>
    )
}
