import React, { Component } from 'react'
// +(this.props.invisible==true ? 'Modalvisible' : 'ModalInvisible')

export class Modal extends Component {
    handleClose = (e) => {
        if ((e.target.id === 'Modal_Wrapper')) this.props.onClose();
    }
    
   
    render() {
        // if (this.props.invisible) {
        return (
            <div id="Modal_Wrapper" className={' Modal_Wrapper overscroll-none  bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-[100] ' +(this.props.invisible==true ? 'visible' : 'invisible')} onClick={() => this.props.onClose()}>
                <div className={'w-[30rem] flex flex-col text-black modal  '+(this.props.invisible==true ? 'Modalvisible' : 'ModalInvisible')}>
                    <button className='text-white text-xl place-self-end' onClick={() => this.props.onClose()}>X</button>
                    <div className="bg-white p-6 rounded-lg">
                        <h5
                            class="text-xl mb-4 font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                        >
                            Search Box
                            {/* <hr className='my-4  border-dotted border-black' /> */}
                            <div className='my-8'>
                                <form action="">
                                    <div class=" text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                        <div class="relative">
                                            <input autocomplete="off" id="Message" name="message" type="text" class="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="message" />
                                            <label for="Subject" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2  peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-sm">Type Here</label>
                                        </div>
                                        <div class="text-right">
                                            <button class="vanila_btn_hover_2 text-white rounded-lg px-5 py-2">Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </h5>
                    </div>
                </div >
            </div >
        )
        // }
        // else {
        //     return null;
        // }
    }
}

export default Modal