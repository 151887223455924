import React, { useEffect, useRef } from 'react';
import Modal from './modal/Modal';
import Logo from "../images/WebshinezTopLogo.png";
import WebShinezWhite from "../images/WebshinezTopLogoWhite.png";
import Nav_Button from "../images/nav_button.png";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import $ from "jquery";
export default function Navbar(props) {
    const [showModal, setShowModal] = useState(false);
    const [thepositionmain, setThepositionmain] = useState('');
    const [scroll, setScroll] = useState(0);
    const [counting_done, setCounting_done] = useState(0);
    const counter = useRef(0);
    useEffect(() => {
        window.addEventListener('scroll', changeScrolling)
        Array.from(document.getElementsByTagName("a")).forEach(e => {
            e.addEventListener("click", () => {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            })
        })
        // window.addEventListener("scroll", () => {
        //     let dropdown_child = document.getElementsByClassName('dorpdown_child');
        //     Array.from(dropdown_child).forEach(ele => {
        //         ele.style.zIndex = "-100";
        //         ele.style.opacity = "0"
        //         ele.style.visibility = "hidden";
        //     })
        // })

        // window.addEventListener("scrollend", () => {
        //     let dropdown_child = document.getElementsByClassName('dorpdown_child');
        //     Array.from(dropdown_child).forEach(ele => {
        //         ele.style.zIndex = null;
        //         ele.style.opacity = null
        //         ele.style.visibility = null;
        //     })
        // })

        let dorpdown_parent = document.getElementsByClassName("dorpdown_parent");

        $(".dorpdown_parent").hover(function () {
            if ($(this).children(".dorpdown_child").hasClass("dropdown_hide")) {

                $(".dorpdown_child").css("z-index", "1000")
                $(".dorpdown_child").css("opacity", null)
                $(".dorpdown_child").css("visibility", null)
                // $(".dorpdown_child").children("").css("display", "block")


                $(this).children(".dorpdown_child").removeClass("dropdown_hide")
                console.log()
            }
        }, function () {
            $(this).children(".dorpdown_child").addClass("dropdown_hide")
            console.log('wow out')
        })



        return () => {
            window.removeEventListener('scroll', changeScrolling);
            window.removeEventListener("scroll", () => {
                let dropdown_child = document.getElementsByClassName('dorpdown_child');
                Array.from(dropdown_child).forEach(ele => {
                    ele.style.zIndex = null;
                    ele.style.opacity = null
                    ele.style.visibility = null;
                })
            })
            window.removeEventListener("scrollend", () => {
                let dropdown_child = document.getElementsByClassName('dorpdown_child');
                Array.from(dropdown_child).forEach(ele => {
                    ele.style.zIndex = null;
                    ele.style.opacity = null
                    ele.style.visibility = null;
                })
            })
            Array.from(document.getElementsByClassName('header')).forEach(e => {
                Array.from(e.getElementsByTagName('a')).forEach(e1 => {

                    e1.addEventListener('click', () => {
                        Array.from(e.getElementsByTagName('a')).forEach(e2 => {
                            if (e2.classList.contains('active')) {
                                e2.classList.remove('active');
                            }
                            if (e2.classList.contains("active2")) {
                                e2.classList.remove("active2");
                            }
                        })
                        if (e1.closest('.dorpdown_child') !== null && e1.closest('.dorpdown_child').classList.contains("dorpdown_child")) {
                            if (!e1.classList.contains('active2')) {
                                e1.classList.add('active2');
                            }
                        }
                        else {
                            if (!e1.classList.contains("active"))
                                e1.classList.add("active");
                        }

                    })
                })
            })
        }
    }, [])

    const navDropdown = () => {

    }

    const changeScrolling = () => {
        let scholl = document.body.scrollTop || document.documentElement.scrollTop;

        setScroll(scholl);
    }


    const listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const why_Chose_us = document.getElementById('why_Chose_us');
        const count = why_Chose_us.querySelectorAll('.count');
        let y = why_Chose_us.offsetTop
        let interval = 50;
        // const height =
        //     document.documentElement.scrollHeight -
        //     document.documentElement.clientHeight
        // const scrolled = winScroll / height
        // const heigh = document.documentElement.scrollHeight
        // const heigh2 = document.documentElement.clientHeight
        if (y - 400 <= winScroll && counting_done === 0 && counter.current === 0) {
            count.forEach((element) => {
                let startValue = parseInt(3 * element.getAttribute('data-val') / 4);
                let endValue = parseInt(element.getAttribute('data-val'));
                // let duration = Math.floor(interval / endValue);
                let duration = 0.0000000001;
                let counter = setInterval(function () {
                    startValue += 1;
                    element.innerText = startValue;
                    if (startValue === endValue) {
                        clearInterval(counter);
                    }
                }, duration)

            })
            setCounting_done(1)
            counter.current = 1;
            // console.log(counting_done)
        }
        setThepositionmain(winScroll)

    }
    const clickNavbar = () => {
        let mobile_navbar = document.getElementsByClassName('mobile_navbar')[0];
        let body = document.getElementById('body');
        // let footer= document.getElementsByClassName('footer')[0];
        // main_component.classList.toggle('hidden')
        // footer.classList.toggle('hidden')
        body.classList.toggle('hidden')
        if (document.body.style.backgroundColor !== "white") {
            document.body.style.backgroundColor = "white";
        }
        else {
            body.style.backgroundColor = "#EEF0F8";
        }
        mobile_navbar.classList.toggle('mobile_section')

    }
    const defaultDropdown = (element) => {
        let default_dropdown = element.target.closest('.default_dropdown');
        let dropdown_id = default_dropdown.getAttribute('data-dropdown');
        let dropdown_id2 = document.querySelectorAll('.dropdown_id');
        let bradcrumbs = document.getElementById('bradcrumbs');

        if (default_dropdown !== null) {
            if (document.getElementById(`${dropdown_id}`).classList.contains('dropdown_hidden')) {
                for (let index = 0; index < dropdown_id2.length; index++) {
                    const element = dropdown_id2[index];
                    element.classList.add('dropdown_hidden');
                }
                document.getElementById(`${dropdown_id}`).classList.remove('dropdown_hidden');
                if (bradcrumbs !== null) {
                    bradcrumbs.style.display = "none"
                }
            }
            else {
                for (let index = 0; index < dropdown_id2.length; index++) {
                    const element = dropdown_id2[index];
                    element.classList.add('dropdown_hidden');
                }
                if (bradcrumbs !== null) {
                    bradcrumbs.style.display = "block"
                }
            }
        }
    }
    const OffdefaultDropdown = () => {
        let bradcrumbs = document.getElementById('bradcrumbs');
        let dropdown_id2 = document.querySelectorAll('.dropdown_id');
        for (let index = 0; index < dropdown_id2.length; index++) {
            const element = dropdown_id2[index];
            if (!element.classList.contains('dropdown_hidden')) {
                element.classList.add('dropdown_hidden');
                if (bradcrumbs !== null) {
                    bradcrumbs.style.display = "block"
                }
            }
        }

    }
    const mobileDropDown = (element) => {
        let mobile_parent_drop = element.target.closest('.mobile_parent_drop');
        if (mobile_parent_drop !== null) {
            let fa_solid = mobile_parent_drop.getElementsByClassName('fa-solid')[0];
            let drop_down_child = mobile_parent_drop.getElementsByClassName('drop_down_child')[0];
            drop_down_child.classList.toggle('hidden');
            drop_down_child.classList.toggle('block');
            mobile_parent_drop.classList.toggle('text-web-color')
            fa_solid.classList.toggle('fa-rotate-90')
        }
    }
    const OffMobileDropDown = (element) => {
        let mobile_parent_drop = element.target.closest('.mobile_parent_drop');
        let mobile_parent_drop_1 = null;

        if (mobile_parent_drop !== null) {
            mobile_parent_drop_1 = mobile_parent_drop.parentElement.parentElement.closest('.mobile_parent_drop');
            let fa_solid = mobile_parent_drop.getElementsByClassName('fa-solid')[0];
            let drop_down_child = mobile_parent_drop.getElementsByClassName('drop_down_child')[0];
            if (fa_solid.classList.contains('fa-rotate-90')) {
                fa_solid.classList.toggle('fa-rotate-90')
            }
            mobile_parent_drop.classList.toggle('text-web-color')
            drop_down_child.classList.toggle('hidden')

        }
        if (mobile_parent_drop_1 !== null) {
            let fa_solid_1 = mobile_parent_drop_1.getElementsByClassName('fa-solid')[0];
            let drop_down_child_1 = mobile_parent_drop_1.getElementsByClassName('drop_down_child')[0];
            fa_solid_1.classList.toggle('fa-rotate-90')
            mobile_parent_drop_1.classList.toggle('text-web-color')
            drop_down_child_1.classList.toggle('hidden')
        }

        clickNavbar()
    }

    //  new code
    const hiddenMobileDropdown = (element) => {
        let mobile_menu_bar = document.getElementsByClassName('mobile_menu_bar')[0];
        mobile_menu_bar.classList.toggle('mobile_menu_bar_invissible')
        mobile_menu_bar.classList.toggle('mobile_menu_bar_vissible')
        // console.log(mobile_menu_bar)
    }

    const mobile_dorpdown = (element) => {
        let mobile_dorpdown = element.target.closest('.mobile_dorpdown_parent').getElementsByClassName('mobile_dorpdown')[0];
        let mobile_menu_bar = document.getElementsByClassName('mobile_menu_bar')[0];
        mobile_menu_bar.classList.toggle('height_increase')
        mobile_dorpdown.classList.toggle('hidden');
        // console.log(mobile_dorpdown)
    }

    var navbarValue = 'md:bg-transparent absolute'
    if (props.navbarStyle) {
        navbarValue = "md:bg-[--Deem-primary-color]"
    }
    else {
        navbarValue = "md:bg-transparent absolute"
    }
    // console.log(thepositionmain)
    return (
        <>
            {/* modal secetion start  */}

            <Modal invisible={showModal} onClose={() => { setShowModal(false) }} />



            {/* modal secetion Ends  */}
            <div className={`mx-auto text-center  bg-white text-md md:text-lg w-full ${navbarValue} z-50 pt-[0.5rem]`}>
                <div className='header  px-4 py-6 mx-auto max-w-[1920px] lg:px-16  xl:px-28 2xl:px-[13rem] '>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='w-44'>
                            <Link to={'/'}><img src={Logo} alt="" /></Link>
                        </div>
                        <div onClick={clickNavbar}><img className='flex-auto w-8 h-8 mx-4 block md:hidden' src={Nav_Button} alt="" /></div>
                        <div className="menu_bar hidden md:block  text-[#ad9595]   ">

                            <ul className='flex '>

                                <li className='upline' >
                                    <Link className=' px-5 py-2  ease-in-out duration-300  hover:text-black  active' to={"/"}>Home</Link></li>
                                <li className='dorpdown_parent'><a className=' px-5  ease-in-out duration-300 hover:text-black' href="#">Services</a>
                                    <div className="dorpdown_child w-[20rem] pt-4 py-2 dropdown_hide">
                                        <ul className=' text-black  py-3'>
                                            <li className=''><Link to={'/services/1'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Website Development</p></Link></li>
                                            <li className=''><Link to={'/services/2'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Digital Marketing</p></Link></li>
                                            <li className=''><Link to={'/services/3'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Domain Registration & Hosting</p></Link></li>
                                            <li className=''><Link to={'/services/4'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>
                                                Software Development</p></Link></li>
                                            <li className=''><Link to={'/services/5'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>
                                                Mobile App Development</p></Link></li>
                                            <li className=''><Link to={'/services/6'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>
                                                e-Commerce Development</p></Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className='dorpdown_parent'><a className=' px-5  ease-in-out duration-300 hover:text-black' href="#">Products</a>
                                    <div className="dorpdown_child w-[13.5rem] pt-4 py-2 dropdown_hide">
                                        <ul className=' text-black  py-3'>
                                            <li className=''><Link to={'/bizzSelf'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>BizzSelf</p></Link></li>
                                            <li className=''><Link to={'/school-solution'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>School Solution</p></Link></li>
                                            <li className=''><Link to={'/madrasha-solution'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Madrasha Solution</p></Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><Link className=' px-5 py-2 ease-in-out duration-300 hover:text-black' to={'/aboutUs'}>About Us</Link></li>

                                <li><a className='px-5 py-2 ease-in-out duration-300 hover:text-black' href="#">Blog</a></li>
                                <li className='  '  >
                                    <Link className=' px-5 py-3 rounded-md transition duration-300 ease-in-out vanila_btn_hover_2  text-white  ' to={'/pages/contactUs'}>Contact Us</Link>
                                </li>
                                <li className='px-5'>
                                    <button
                                        type='button'
                                        className='inline-block px-3 py-2 rounded-full vanila_btn_hover_1 ease-in-out duration-300 text-red-700 hover:text-black text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca]  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#ef3e53] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#ef3e53] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
                                        onClick={() => { setShowModal(true) }}><span className=''><i className="fa-solid fa-magnifying-glass"></i></span></button>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className={`header  px-auto px-4 py-6 mx-auto   w-full   lg:px-16   max-[768px]:hidden stick top-0 transition-opacity duration-100 xl:px-28  ${scroll > 200 ? " mx-auto fixed  bg-[--Deem-primary-color]   " : "bg-transparent hide "}`}>
                    <div className='flex flex-row mx-auto justify-between items-center  min-[1968px]:w-[1527px]'>
                        <div className='w-44'>
                            <Link to={'/'} className=''><img src={WebShinezWhite} alt="" /></Link>
                        </div>
                        <div onClick={clickNavbar} className='bg-white'><img className='flex-auto w-8 h-8 mx-0 block md:hidden' src={Nav_Button} alt="" /></div>
                        <div className="menu_bar hidden md:block  text-[#ad9595]   ">

                            <ul className='flex '>

                                <li className='upline' >
                                    <Link className=' px-5 py-2  ease-in-out duration-300  hover:text-black  active' to={"/"}>Home</Link></li>
                                <li className='dorpdown_parent'><a className='px-5  ease-in-out duration-300 hover:text-black' href="#">Services</a>
                                    <div className="dorpdown_child w-[20rem] pt-4 py-2 dropdown_hide">
                                        <ul className=' text-black  py-3'>
                                            <li className=''><Link to={'/services/1'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Website Development</p></Link></li>
                                            <li className=''><Link to={'/services/2'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Digital Marketing</p></Link></li>
                                            <li className=''><Link to={'/services/3'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Domain Registration & Hosting</p></Link></li>
                                            <li className=''><Link to={'/services/4'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>
                                                Software Development</p></Link></li>
                                            <li className=''><Link to={'/services/5'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>
                                                Mobile App Development</p></Link></li>
                                            <li className=''><Link to={'/services/6'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>
                                                e-Commerce Development</p></Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className='dorpdown_parent'><a className='px-5  ease-in-out duration-300 hover:text-black' href="#">Products</a>
                                    <div className="dorpdown_child pt-4 py-2 dropdown_hide">
                                        <ul className=' text-black w-48 py-3'>
                                            <li className=''><Link to={'/bizzSelf'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>BizzSelf</p></Link></li>
                                            <li className=''><Link to={'/school-solution'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>School Solution</p></Link></li>
                                            <li className=''><Link to={'/madrasha-solution'} className=' group ps-5 text-left py-3  ease-in-out duration-700 ' href=""><span className='ease-in-out duration-700 align-middle  h-[2px] w-1 group-hover:w-5 me-2 bg-[--Primary-color] inline-block'></span><p className='group-hover:text-[--Primary-color] ease-in-out duration-700 inline-block'>Madrasha Solution</p></Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><Link className=' px-5 py-2 ease-in-out duration-300 hover:text-black' to={'/aboutUs'}>About Us</Link></li>

                                <li><a className='px-5 py-2 ease-in-out duration-300 hover:text-black' href="#">Blog</a></li>
                                <li className='  '  >
                                    <Link className=' px-5 py-3 rounded-md transition duration-300 ease-in-out vanila_btn_hover_2  text-white  ' to={'/pages/contactUs'}>Contact Us</Link>
                                </li>
                                <li className='px-5'>
                                    <button
                                        type='button'
                                        className='inline-block px-3 py-2 rounded-full vanila_btn_hover_1 ease-in-out duration-300 text-red-700 hover:text-black text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca]  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#ef3e53] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#ef3e53] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
                                        onClick={() => { setShowModal(true) }}><span className=''><i className="fa-solid fa-magnifying-glass"></i></span></button>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                {/* modified mobile navbar starts */}

                <div id='mobile_section' className=' top-0'>
                    <div className='mobile_navbar bg-white  lg:hidden w-full   z-50 mobile_section ' style={{ height: '' }}>
                        <div className='flex justify-between items-center'>
                            <Link to={'/'} onClick={OffdefaultDropdown} ><img className='w-44 my-4  mx-4' src={Logo} alt="" /></Link>
                            <div onClick={clickNavbar} className=' text-xl text-web-color mx-4 '><i className="fa-solid fa-circle-xmark"></i></div>
                        </div>
                        <ul className=' ms-4 text-base text-black mb-8' >
                            <li className=' ms-5 '><Link to={"/"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Home</Link></li>
                            <hr />
                            {/* <li className='mobile_parent_drop ms-5 '><div onClick={mobileDropDown} className='py-4  flex justify-between  '><span>Products</span>  <span className='mx-4'><i className="fa-solid fa-caret-right"></i></span></div> */}
                            {/* <ul className='drop_down_child hidden ms-4  mb-4 text-base text-black ' > */}
                            <li className='mobile_parent_drop ms-5 '><div onClick={mobileDropDown} className='py-4  flex justify-between  '><span>Services</span>  <span className='mx-4'><i className="fa-solid fa-caret-right"></i></span></div>
                                <ul className='drop_down_child hidden ms-4  mb-4 text-base text-black ' >
                                    <li className=' ms-5 '><Link to={"/services/1"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Website Development</Link></li>
                                    <hr />
                                    <li className=' ms-5 '><Link to={"/services/2"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Digital Marketing</Link></li>
                                    <hr />
                                    <li className=' ms-5 '><Link to={"/services/3"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Domain Registration & Hosting</Link></li>
                                    <hr />
                                    <li className=' ms-5 '><Link to={"/services/4"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Software Development</Link></li>
                                    <hr />
                                    <li className=' ms-5 '><Link to={"/services/5"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Mobile App Development</Link></li>
                                    <hr />
                                    <li className=' ms-5 '><Link to={"/services/6"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>e-Commerce Development</Link></li>

                                </ul>
                            </li>
                            <hr />
                            <li className='mobile_parent_drop ms-5 '><div onClick={mobileDropDown} className='py-4  flex justify-between  '><span>Products</span>  <span className='mx-4'><i className="fa-solid fa-caret-right"></i></span></div>
                                <ul className='drop_down_child hidden ms-4  mb-4 text-base text-black ' >
                                    <li className=' ms-5 '><Link to={"/bizzSelf"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Bizzself</Link></li>
                                    <hr />
                                    <li className=' ms-5 '><Link to={"/school-solution"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>School Solution</Link></li>
                                    <hr />
                                    <li className=' ms-5 '><Link to={"/madrasha-solution"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Madrasha Solution</Link></li>

                                </ul>
                            </li>
                            <hr />
                            {/* <li className='mobile_parent_drop ms-5 '><div onClick={mobileDropDown} className='py-4  flex justify-between  '><span>Company</span>  <span className='mx-4'><i className="fa-solid fa-caret-right"></i></span></div> */}
                            {/* <ul className='drop_down_child hidden ms-4  mb-4 text-base text-black ' > */}
                            {/* <li className=' ms-5 '><Link to={"/company"} onClick={OffMobileDropDown} className='py-4  flex   '><span className='pe-4'>Go to Company</span><i className="fa-solid fa-arrow-right mt-1"></i></Link></li>
                                            <hr /> */}
                            <li className=' ms-5 '><Link to={"aboutUs"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>About Us</Link></li>

                            <hr />
                            {/* <li className=' ms-5 '><Link to={"Partnership"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Partnership</Link></li>
                                            <hr />
                                            <li className=' ms-5 '><Link to={"lifeAtSoftifybd"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Life at Softyfybd</Link></li>
                                            <hr />
                                            <li className=' ms-5 '><Link to={"JobsAndCareer"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Jobs and Career</Link></li>
                                            <li className=' ms-5 '><Link to={"CompanyOverview"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Company Overview</Link></li>
                                            <hr /> */}
                            <li className=' ms-5 '><Link to={"/pages/contactUs"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Contact Us</Link></li>

                            {/* </ul> */}
                            {/* </li> */}
                            {/* <hr /> */}
                            {/* <li className='mobile_parent_drop ms-5 '><div onClick={mobileDropDown} className='py-4  flex justify-between  '><span>Resourse</span>  <span className='mx-4'><i className="fa-solid fa-caret-right"></i></span></div>
                                        <ul className='drop_down_child hidden ms-4  mb-4 text-base text-black ' >
                                            <li className=' ms-5 '><Link to={"/company"} onClick={OffMobileDropDown} className='py-4  flex   '><span className='pe-4'>Go to Company</span><i className="fa-solid fa-arrow-right mt-1"></i></Link></li>
                                            <hr />
                                            <li className=' ms-5 '><Link to={"newsAndblogs"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>News and Blogs</Link></li>

                                            <hr />
                                            <li className=' ms-5 '><Link to={"termsofuse"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Terms Of Use</Link></li>
                                            <hr />
                                            <li className=' ms-5 '><Link to={"privacynotice"} onClick={OffMobileDropDown} className='py-4  flex justify-between  '>Privacy Notice</Link></li>
                                            <hr />
                                        </ul>
                                    </li>
                                    <hr /> */}

                            {/* </ul>
                            </li> */}
                            <hr />


                        </ul>


                        <div id='contact-hero' className="container ps-8 mt-[90%]  ">
                            <div className="flex flex-col justify-center  sm:flex-row sm:justify-between ">
                                <div className='mb-4 text-xl'>
                                    <p>Hot Line: +8801913153450</p>
                                </div>
                                <div className='mb-4 text-xl'>
                                    <ul className='flex  mx-auto justify-between  items-center'>
                                        <li className='border-s-2 border-[gainsboro] px-4'><a href=""><i className="fa-brands fa-facebook"></i></a></li>
                                        <li className='border-s-2 border-[gainsboro] px-4'><a href=""><i className="fa-brands fa-linkedin"></i></a></li>
                                        <li className='border-s-2 border-[gainsboro] px-4'><a href=""><i className="fa-brands fa-twitter"></i></a></li>
                                        <li className='border-x-2 border-[gainsboro] px-4'><a href=""><i className="fa-brands fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >

                {/* modified mobile navbar starts */}


            </div >

        </>
    )
}
